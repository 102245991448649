import React,{useState, useEffect, Suspense} from 'react';
//import Post from './Post/Post';

import useStyles from './style';

import '../Footer/Footer.css';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import editBtn from './Post/img/editYellowBtn.png';
import DeleteBtn from './DeleteBtn.png';

import './Posts.css';
import crossBtn from './cross.png';
import './Post/Post.css';
import decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { likePost, deletePost } from '../../actions/posts';
import DisasterRelief from '../DisasterRelief/DisasterRelief';
import { FormControlUnstyledContext } from '@mui/base';


function Posts(props) {
 

    const Post = React.lazy(() => import('./Post/Post'));
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isShownTwo, setIsShownTwo] = useState(false);
    const {posts, isLoading } = useSelector((state) => state.posts);
    
    const [toggle, setToggle] = useState(true);
    const [togglePageTwo, setTogglePageTwo] = useState(true);
    const [togglePageThree, setTogglePageThree] = useState(true);
    const [togglePageFour, setTogglePageFour] = useState(true);

    const [conTwo, setCon] = useState(false);
    const [togglePageFive, setTogglePageFive] = useState(true);
    const [togglePageSix, setTogglePageSix] = useState(true);
    const [togglePageSeven, setTogglePageSeven] = useState(true);

    const [togglePageEight, setTogglePageEight] = useState(true);
    const [togglePageNine, setTogglePageNine] = useState(true);
    const [togglePageTen, setTogglePageTen] = useState(true);
    const [togglePageRest, setTogglePageRest] = useState(true);
    const [toggleExpandedBox, setToggleExpandedBox] = useState(false);
    const [isShown, setIsShown] = useState(false);

    const [data, setData] = useState();
    const { setCurrentId, dataFromParent, dataNew, allposts } = props;

    const [state, setState] = useState('');
    
    const [stateTwo, setStateTwo] = useState('');
    const [newestPosts, setNewestPosts] = useState([]);

    const [timeLeft, setTimeLeft] = useState(null);
    const [timeLeftToggle, setTimeLeftToggle] = useState(false);
    const [counter, setCounter] = useState(0);
    const history = useNavigate();

   

    const handleSubmit = () =>{

      console.log("posts?.length: Post ", posts?.length);
      let number = posts?.length;
      //props.onSubmit(number);
      async function fetchData() {
        // You can await here
        //await dispatch(getPosts());
        //await setTimeLeft(2);
        // ...
       await window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
      fetchData();
      
    }


    const openPost = () => {
      setToggle(!toggle);

    
      
      
      

    }

    const handlePageTwo = () => {
      
      setTogglePageTwo(!togglePageTwo);
      console.log(togglePageTwo);
      //props.dataNew = false;

    }

    const handlePageThree = () => {
      
      setTogglePageThree(!togglePageTwo);
      console.log(togglePageTwo);

    }

    const handlePageFour = () => {
      
      setTogglePageFour(!togglePageTwo);
      console.log(togglePageTwo);

    }


    const handlePageFive = () => {
      
      setTogglePageFive(!togglePageTwo);
      console.log(togglePageTwo);

    }

    const handlePageSix = () => {
      
      setTogglePageSix(!togglePageTwo);
      console.log(togglePageTwo);

    }

    const handlePageSeven = () => {
      
      setTogglePageSeven(!togglePageTwo);
      console.log(togglePageTwo);

    }


    const handlePageEight = () => {
      
      setTogglePageEight(!togglePageTwo);
      console.log(togglePageTwo);

    }


    const handlePageNine = () => {
      
      setTogglePageNine(!togglePageTwo);
      console.log(togglePageTwo);

    }


    const handlePageTen = () => {
      
      setTogglePageTen(!togglePageTwo);
      console.log(togglePageTwo);

    }

    const handlePageRest = () => {
      
      setTogglePageRest(!togglePageTwo);
      console.log(togglePageTwo);

    }

    const ClosePost = () => {
      setToggle(!toggle);
        
      history(`/posts/search?searchQuery=${stateTwo ||'none'}&tags=${state ||'none'}/${''}`);

    }

    useEffect(() => {
      
      const newTag = window.location.href.split("/")[5].split("=")[2];
      const newSearch =  window.location.href.split("/")[5].split("=")[1].split("&")[0];
      if(newTag != 'none')
      {
        setState(newTag);
        setStateTwo('');
      }
      if(newSearch != 'none')
      {
        setStateTwo(newSearch);
        setState('');
      }
      
     
    }, [window.location.href])
    
    

    const getData = (data) =>{
      
     console.log("test",data);
     console.log(conTwo);
    
     //props.onSubmit(data);
      setData(data);
      setToggle(!toggle);
      setToggleExpandedBox(true);
      
      
      
    }

    
   
  

    const getDataFour = (data) =>{
      
      console.log("test",data);
      console.log(conTwo);
      if(conTwo == true)
      {
       console.log("in if state post");
      }
      props.onSubmit(data);
       //setData(data);
       setToggle(!toggle);
       setToggleExpandedBox(true);
       
       
       
     }


   
  /*
    const ascOrder = () =>
    {
      setState(posts.sort(function(a, b){
        return a-b
      }));
      setState({ascMsg:"Ascending Order", descMsg: false})
    }

    const descOrder = () =>
    {
      
      setState(posts.sort(function(a, b){
        return b-a
      }));
      setState({ascMsg:false, descMsg:"Descending Order"})
    }
  
    useEffect(() => {
      setNewestPosts(posts.reverse()); 
      
    }, [])
   

useEffect(() => {
  console.log("newlist 3been here");
  var newlist = posts.filter(x => x.Country === "Denmark");
  console.log(newlist);
  setNewestPosts(newlist);

  
}, [])

 */

const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  
const location = useLocation();

useEffect(() => {
  const token = user?.token;

  if (token) {
    const decodedToken = decode(token);

    if (decodedToken.exp * 1000 < new Date().getTime());
  }

  setUser(JSON.parse(localStorage.getItem('profile')));
}, [location]);

const [finishStatus, setfinishStatus] = useState(false);

const onBackButtonEvent = (e) => {
  e.preventDefault();
  if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
          setfinishStatus(true)
          // your logic
          props.history.push("/");
      } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false)
      }
  }
}






  useEffect(() => {
    
    handleSubmit();
   
    
    if(counter == 1)
    {
      setTimeLeftToggle(true);
    
    }
    else{
      setCounter(1);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
   
    

  })
  useEffect(() => {

    async function fetchData() {
      // You can await here
      //await dispatch(getPosts());
      //await setTimeLeft(2);
      // ...
    }
    fetchData();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    
    
  }, [])


  useEffect(() => {
    if(timeLeft===0){
       console.log("TIME LEFT IS 0");
       setTimeLeft(null);
       setTimeLeftToggle(true);

    }


    console.log(timeLeft);
    
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
    
  if (!posts.length && !isLoading) return <>
  <div className='noPosts' id='noPostsID'>Still no projects</div>
  <div className='zoomSix'>
  <Suspense fallback={<div></div>}>
                  <span className='FooterBoxPostNoTwo'>
                      <p className='FooterBoxTextOneNoPost'> Ingeniører Uden Grænser/ Engineers Without Borders Denmark</p>
                    <p className='FooterBoxTextTwoNoPost'>Office: +4570274006/ Email: info@iug.dk</p>

                    </span>
            </Suspense>
            </div>
  
  </>;


  const duration = 1000; // ms
  const delay = 500; // ms
  const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * i}ms forwards`;
    
    return (


      isLoading ? 
      
      <>
     
      <dix className='CircularProgress'>
        <CircularProgress className='CircularProgress'/> 
      </dix>
      
   
        
     
      
      </>
      
      
      
      : (
       
       
       
        <div className='gridContainer'>
        {props.dataNew ?
           <>
          {togglePageTwo && <div className='gridContainer'>
          
          {posts.map((post, i) => (
             <div >
              <Suspense fallback={<div><div className='LinkCardBox'></div></div>}>
             {timeLeftToggle &&
              
                  <div onclick={openPost} className='openPost'>
                    <Post post={post} setCurrentId={setCurrentId} onSubmit={getData}  i={i}/>    
                  </div>  
              
              }
              </Suspense>
              {user?.result &&
              <>
              <button  className='editBtnNew' onClick={() =>{console.log("edit btn", true); setCon(true); getDataFour(post)}}> <img src={editBtn}  width="40px" height="40px" /></button>
              <button  className='deleteBtn' onClick={() => dispatch(deletePost(post._id))}> <img src={DeleteBtn}  width="40px" height="40px" /></button>
              </>
              }
            </div>

          ))}
          <Suspense fallback={<div></div>}>
                  <span className='FooterBoxPost'>
                      <p className='FooterBoxTextOnePost'> Ingeniører Uden Grænser/ Engineers Without Borders Denmark</p>
                    <p className='FooterBoxTextTwoPost'>Office: +4570274006/ Email: info@iug.dk</p>

                    </span>
            </Suspense>
         
          </div>
          }


          </>

          :
          <>
          <div>

          {!props.dataNew && 
            <div className='closeExpandedBox'></div>}
          </div>
          <div onClick={ClosePost} className='cross' >
            <img loading='lazy' src={crossBtn} width="25" height="25" />
          </div>
          <div className='expandedBox' onClick={ClosePost}>
          
           
          
           {toggleExpandedBox && <>

           
            <DisasterRelief projectFile={data.selectedFile} projectID={data._id} className='pdfFile'/>
             
              
           
                  <span className='FooterBoxEx'>
                      <p className='FooterBoxTextOneEx'> Ingeniører Uden Grænser/ Engineers Without Borders Denmark</p>
                    <p className='FooterBoxTextTwoEx'>Office: +4570274006/ Email: info@iug.dk</p>

                    </span>
                   
            </>
            
            

          }
                
            </div>
            </>
    
          }
        </div>
      
      )
      
    );
  };

export default Posts


/*
<iframe  loading="eager" className='pdfFile' src={'https://storage.googleapis.com/ewbdk32/undefined/' + data.selectedFile} width="120%" zoom="100%" height="100%" />
           

<button  className='editBtnNew' onClick={() => setCurrentId(post._id)}> <img src={editBtn}  width="40px" height="40px" /></button>
               
<embed src={post.selectedFileIcon} width="800px" height="2100px" />

setToggle(!toggle);

  function itemKey(index, data) {
    const item = posts[index];
    console.log("item: ", item);
    return item;
  }

  const Item = () => {return(<div >
  <Suspense fallback={<div><div className='LinkCardBox'></div></div>}>
      <div onclick={openPost} className='openPost'>
        <Post post={itemKey} setCurrentId={setCurrentId} onSubmit={getData}/>    
      </div>  
  </Suspense>
  <List
            
            height={2000}
            itemCount={1000}
            itemSize={200}
            width={2000}
          >
            {Item}
          </List>

</div>)};


*/