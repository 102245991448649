

import './Navbar.css'
import MapSideLink from './img/MapSideLink.png';
import logo from './img/LogoTop_TextRight_Green_English.png';
import supportIUGOne from './img/supportIUG.PNG';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import decode from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Avatar, Button } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import * as actionType from '../../constants/actionTypes';
import allProjectBtn from './img/allProjectBtn.png';

import Auth from '../Auth/Auth.js';
  
import MapOverview from './img/MapOverview.png';
import '../FoodSecurity/FoodSecurity.css';
import './Navbar.css';

import Ok from '../FoodSecurity/img/Ok.png';
import cookies from '../FoodSecurity/img/cookies.png';

import supportBtn from './img/supportBtn.png';
import Map from '../Map/Map.js';
          
function Navbar(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const [adminToggle, setAdminToggle] = useState(false);

  const [toggleProcejtBtnHover, setToggleProcejtBtnHover] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleCookies, setToggleCookies] = useState(false);


  //const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  
  const handleAdmin = () => {

    setAdminToggle(true);
    props.onSubmit(true);
    history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${''}`);

  }
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history('/');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  const handleToggle = () => {
    setToggle(!toggle);
  }

  const handleCookies = () => {
    setToggleCookies(true);
  }

  return (
      <>

        
       
          <div id='header'></div>

          <a href="https://www.google.com/maps/d/viewer?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ll=8.524343052360175%2C3.117302500000008&z=3" rel="noopener noreferrer" target="_blank"> 
                
                  <div className='MapOverview'>google map</div>
                  
               
          
                </a> 
         

          <a href="https://iug.dk/">             
            <img loading="lazy" src={logo} className='LogoTop'/>
              
          </a>  
          
          <a href="/"> 
           
                <img  className='MapSideLink' src={MapSideLink}/>
          
          </a> 


           
        
         
         
              

            {user?.result ? (
              <div>
                <div className='name'>
                  <Avatar  alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>
                </div>
               
                  <button className='logout' onClick={logout}>Logout</button>
               
                 <a href="https://www.google.com/maps/d/viewer?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ll=8.524343052360175%2C3.117302500000008&z=3" rel="noopener noreferrer" target="_blank"> 
           
                
              
          
                </a> 
              </div>
              
            ) : (
              <>
              <div className='adminLogintWO'id='adminLogin' onClick={handleAdmin}>
               
                  LOGIN
            
              </div>
              
              <div className='zoomTwo'> 
              {adminToggle &&
                <>
                <div className='fullBox'>
                
                
               
                  <Auth/>

                  <p className='text'>
       
                  ‘Engineers Without Borders - Denmark’ is a technical humanitarian organization of volunteers that seek to improve the livelihood for people living in poverty. We collaborate with local and international aid organizations to create a better future.  <br/>  <br/>
                  Our work covers a wide field: We build schools and health clinics, We provide clean water and improved sanitary conditions, so that fewer children will fall ill or die from cholera or typhoid fever, bad toilet conditions and a lack of clean drinking water.
                  We are a non-profit organization financed by contributions from members, and generous donations from private and public donors.  <br/> <br/> ‘Engineers Without Borders’ has 1.800 members who support us and about 250 are actively engaged in our work.
                  This is the EWB-Dk projects database login page for admins, go to the index site to start experiencing the EWB-Dk projects: <br/>   <a href='./'>https://www.ewbprojects.dk/</a>
                   </p>
                
                </div>
                
                </>}
                </div>

           

               
              </>
            )}
 
         
         


      
    </>
  )
}

export default Navbar


/*
 <div className='nameText'>
                <Typography  variant="h6">{user?.result.name.split(" ")[0]}</Typography>
                </div>
               

 <a href="https://www.google.com/maps/d/viewer?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ll=8.524343052360175%2C3.117302500000008&z=3" rel="noopener noreferrer" target="_blank"> 
                
                  <div className='MapOverview'>community-level-map</div>
                  
               
          
                </a> 

   <a href="https://iug.dk/volunteer">
               
                <div className='volunteerTwo'>
                  VOLUNTEER
                </div>
              
              </a> 


 <div className='allProjectBtnNavbar'  onMouseEnter={() => setToggleProcejtBtnHover(true)} onMouseLeave={() => setToggleProcejtBtnHover(false)}></div>
         
         
          <div className='headerBox'>
            <a href="https://iug.dk/bliv-medlem">
              <img src={supportBtn} /> 
            </a>
          </div>
     


          <div className='headerBoxTwo'>
            <a href="https://iug.dk/bliv-medlem">
              <div className='headerBoxText'>Become&nbsp;member</div> 
            </a>
          </div>

          <a href="https://iug.dk/contact">
            <div className='headerContact' onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)}>
              Contact
            </div>
            {toggle &&
            <div className='contactHover'>
              EWB-dk contact info
              </div>}
          </a>
          

*/