import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from './styles';
import { signin, signup } from '../../actions/auth';
import Input from './Input';
import './Auth.css'
import { AUTH } from '../../constants/actionTypes';
import Icon from './icon';
import BackBtn from '../Construction/img/back.png';import React, { useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { getUsers, deleteUser } from '../../actions/auth';
import auth from '../../reducers/auth';
import {useLocation } from 'react-router-dom';

import deleteBtn from './img/deleteBtn.png';
import whiteSpace from './img/whiteSpace.png';
import Map from '../Map/Map.js';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', token:'' };

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const domainUrl = 'http://localhost:5000';
  const [csrfTokenState, setCsrfTokenState] = useState('');
  const [haveWeReceivedPostResponseState, setHaveWeReceivedPostResponseState] = useState("not yet")



  async function getCallToForm() {
    const url = '/form';
    let fetchGetResponse = await fetch(`${domainUrl}${url}`, {
        method: 'GET',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        mode: 'cors'
    })
    let parsedResponse = fetchGetResponse.json();
    setCsrfTokenState(parsedResponse)
}

useEffect(() => {
  getCallToForm();
  }, [])
  
  async function testCsurfPostClick() {
    const url = '/process';
    let fetchPostResponse = await fetch(`${domainUrl}${url}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "xsrf-token": csrfTokenState,
        },
        credentials: "include",
        mode: "cors"
    })
    let parsedResponse = await fetchPostResponse.text();
    setHaveWeReceivedPostResponseState(parsedResponse);
}


  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (isSignup) {
      dispatch(signup(form, history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`)));
      history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`);
    } else {
    
      dispatch(signin(form, history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`)));
      history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`);
    }
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;

    try {
      dispatch({ type: AUTH, data: { result, token } });

      history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`);
         
    } catch (error) {
      console.log(error);
    }

    testCsurfPostClick();
  };

  useEffect(() => {
    
    console.log("here signup", user?.result)
    if(user?.result){
      console.log("if here singup")
      setIsSignup(true);

    }
    async function fetchData() {
      // You can await here
      const lisuser  = await dispatch(getUsers());
    
        console.log("user from Auth page: ", lisuser);
        setUsers(lisuser)
      
      //console.log("user: ", lisuser)
      console.log("have data");
      //await setTimeLeft(5);
      // ...
    }

    fetchData();
    
    
  }, [])
  

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  useEffect(() => {
      const token = user?.token;

      if (token) {
        const decodedToken = decode(token);

        if (decodedToken.exp * 1000 < new Date().getTime());
      }

      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);
 

  const googleError = () => alert('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });
  
  

  return (
    <>
    <img src={whiteSpace} className='whiteSpace'/>
    {isSignup ? <div className='zoomAuth'>
    <Container component="main" maxWidth="xs">
   <div>
  {user?.result &&
  <div>
    <div className='adminList'> 
    <div className='adminHeader'>Administrators</div>
    
    <div className='greyBox'></div>
    {users.map((user) => (
             <div >
              {user.email}

            
              <button className='deleteBtn' onClick={async() => {if(user._id!=='634025fe7507c5871109d168'){await dispatch(deleteUser(user._id)); window.location.reload(); }}}><img src={deleteBtn}/> Delete</button>
            </div>
      ))}
    
    </div>
    </div>
    }
    
    
    </div>
    

      <a href='./'>
        <img src={BackBtn} className='backAuth'></img>
        
      </a>
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            { isSignup && (
            <>
            {user?.result &&
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            }
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && 
            <>
             {user?.result &&
              <>
                <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> 
              </>
             }
            </>
            }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? <>{user?.result && <> Sign Up </>} </> : 'Sign In' }
          </Button>
          <GoogleLogin
            clientId="564033717568-e5p23rhvcs4i6kffgsbci1d64r8hp6fn.apps.googleusercontent.com"
            render={(renderProps) => (
              <>
              {!user?.result && 
              <> 
               
               </>} 
               </>
            )}
            onSuccess={googleSuccess}
           
            cookiePolicy="single_host_origin"
          />
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? <>{!user?.result && <>Already have an account? Sign in</>} </> :  <>{user?.result && <>Sign Up</>} </> }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
    </div>:     <div className='zoomAuthTwo'>
    <Container component="main" maxWidth="xs">
   <div>
  {user?.result &&
  <div>
    <div className='adminList'> 
    <div className='adminHeader'>Administrators</div>
    
    <div className='greyBox'></div>
    {users.map((user) => (
             <div >
              {user.email}

            
              <button className='deleteBtn' onClick={async() => {if(user._id!=='634025fe7507c5871109d168'){await dispatch(deleteUser(user._id)); window.location.reload(); }}}><img src={deleteBtn}/> Delete</button>
            </div>
      ))}
    
    </div>
    </div>
    }
    
    
    </div>
    

      <a href='./'>
        <img src={BackBtn} className='backAuth'></img>
      </a>
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            { isSignup && (
            <>
            {user?.result &&
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            }
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && 
            <>
             {user?.result &&
              <>
                <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> 
              </>
             }
            </>
            }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? <>{user?.result && <> Sign Up </>} </> : 'Sign In' }
          </Button>
          <GoogleLogin
            clientId="564033717568-e5p23rhvcs4i6kffgsbci1d64r8hp6fn.apps.googleusercontent.com"
            render={(renderProps) => (
              <>
              {!user?.result && 
              <> 
               
               </>} 
               </>
            )}
            onSuccess={googleSuccess}
           
            cookiePolicy="single_host_origin"
          />
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? <>{!user?.result && <>Already have an account? Sign in</>} </> :  <>{user?.result && <>Sign Up</>} </> }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
    </div>}
    </>
   
  );
};

export default SignUp;


/*
 onFailure={googleError}

  <Button className={classes.googleButton} color="primary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                  Google Sign In
                </Button>
 */