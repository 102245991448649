import React from 'react'

function pdfFile(props) {

    const { post, setCurrentId } = props;
  return (
    <div><embed src={post.selectedFileIcon} width="1980px" height="1020px" /></div>
  )
}

export default pdfFile