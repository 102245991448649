import React, { useEffect, useState  } from 'react';
import Navbar from '../Navbar/Navbar'

import userGuide from '../../LogoBox/UserguideForEWB.JPG';
import './DisasterRelief.css';
import uploadBack from './img/uploadSite.png';
import asPDF from './img/asPdfNewColor.png';
import UploadLinkBtn from './img/UploadLinkBtn.png';
import moment from 'moment';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getPost, getPostsBySearch } from '../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';

function DisasterRelief({projectFile, projectID}) {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const history = useNavigate();
 
  const { id } = useParams();
 

  useEffect(() => {
    if(!id){
      //console.log("here with data", data._id);
      const tagsNewUrl = window.location.href.split("/")[5].split("=")[2];
      //console.log("distwo", window.location.href.split("/")[5].split("=")[2]);
      history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${projectID}`);
      
      
    }
  }, []);

  console.log("dis", window.location.href.split("/")[6]);

  
 

  return (
    <div>

     
      <iframe  loading="lazy" className='pdfFileTwo' src={'https://storage.googleapis.com/ewbdk32/undefined/' + projectFile} width="120%" zoom="100%" height="100%" />
           
     
    </div>
  )
}

export default DisasterRelief


/*
 <Navbar></Navbar>
      <a href='/posts'>
        <img src={uploadBack} className='uploadBackForEWB'></img>
      </a>
*/