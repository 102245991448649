export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const CLEAR_DATA = 'CLEAR_DATA';
export const COUNTER = 'COUNTER';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';

export const FETCH_POST = 'FETCH_POST';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';



