import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import './index.css';

import { HashRouter } from 'react-router-dom';

if (process.env.NODE_ENV !== "development")
    console.log = () => {};
    
const store = createStore(reducers, compose(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <div className='fill-window '>
    <HashRouter hashType='hashbang'>
      <App/>
    </HashRouter>
    </div>
  </Provider>,
  document.getElementById('root'),
);


