import React,{useEffect, useState} from 'react'

import { GoogleMap, useLoadScript, Marker, InfoBox, InfoWindow } from "@react-google-maps/api";
import { useSelector } from 'react-redux';
import './Map.css'
import { Grid, CircularProgress } from '@material-ui/core';

import { useMemo } from "react";

import img from '../../LogoBox/1Afskaf_fattigdom.PNG';
import { getPosts } from '../../actions/posts';
import { useDispatch } from 'react-redux';

import pdfFileTwo from '../../projectTesters/2 Projekt Test .pdf';

import crossBtn from '../Posts/cross.png';




import iconOne from './GoogleNeedles/1.png';
import iconTwo from './GoogleNeedles/2.png';
import iconThree from './GoogleNeedles/3.png';
import iconFour from './GoogleNeedles/4.png';
import iconFive from './GoogleNeedles/5.png';
import iconSix from './GoogleNeedles/6.png';
import iconSeven from './GoogleNeedles/7.png';
import iconEight from './GoogleNeedles/8.png';
import iconNine from './GoogleNeedles/9.png';
import iconTen from './GoogleNeedles/10.png';

import iconTwelve from './GoogleNeedles/12.png';
import iconThirteen from './GoogleNeedles/13.png';
import iconFourteen from './GoogleNeedles/14.png';
import iconFifteen from './GoogleNeedles/15.png';
import iconSixteen from './GoogleNeedles/16.png';
import iconSeventeen from './GoogleNeedles/17.png';
import iconElevenRight from './GoogleNeedles/11.png';
import { useParams, useNavigate, Link } from 'react-router-dom';
import NoIcon from './GoogleNeedles/noChoice.png';

import NoChoiceGreen from './GoogleNeedles/noChoiceGreen.png';
import Post from '../Posts/Post/Post';

import MapChangeBtn from './MapChangeBtn.png';
import MapChangeBtnHover from '../Navbar/img/HoverCommunityMapBtn.png';

export default function Home() {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: 'AIzaSyDax4lDkP6Q4U7R2Ta8XRdbSIVwM8_o75I',
    });
  
    if (!isLoaded) return <div>Loading...</div>;
    return <Map />;
  }
  


function Map(props) {
    const {posts} = useSelector((state) => state.posts);
    const center = useMemo(() => ({ lat: 30.32, lng: 5.03 }), []);
    const [timeLeft, setTimeLeft] = useState(null);
    const dispatch = useDispatch();
    const [markerList, setMarkerList] = useState([]);
    const [markerListTwo, setMarkerListTwo] = useState([]);
    const [markerListThree, setMarkerListThree] = useState([]);
    const [x, setX] = useState([]);
    const history = useNavigate();

    const [selecteMarker, setSelecteMarker] = useState();
    const [selecteMap, setSelecteMap] = useState(false);
    const [selecteMarkerBool, setSelecteMarkerBool] = useState(false);
    const { setCurrentId, dataForMap,  disabled=true } = props;
    const [iconImgNo, setIconImgNo] = useState(NoIcon); 
    const [iconImg, setIconImg] = useState(iconOne); 
    const [iconImgTwo, setIconImgTwo] = useState(iconTwo); 
    const [iconImgThree, setIconImgThree] = useState(iconThree); 
    const [iconImgFour, setIconImgFour] = useState(iconFour); 
    const [iconImgFive, setIconImgFive] = useState(iconFive); 
    const [iconImgSix, setIconImgSix] = useState(iconSix); 
    const [iconImgSeven, setIconImgSeven] = useState(iconSeven); 
    const [iconImgEight, setIconImgEight] = useState(iconEight); 
    const [iconImgNine, setIconImgNine] = useState(iconNine); 
    const [iconImgTen, setIconImgTen] = useState(iconTen); 
    const [iconImgEleven, setIconImgEleven] = useState(iconElevenRight); 
    const [iconImgTwelve, setIconImgTwelve] = useState(iconTwelve); 
    const [iconImgThirteen, setIconImgThirteen] = useState(iconThirteen); 
    const [iconImgFourteen, setIconImgFourteen] = useState(iconFourteen); 
    const [iconImgFifteen, setIconImgFifteen] = useState(iconFifteen); 
    const [iconImgSixteen, setIconImgSixteen] = useState(iconSixteen); 
    const [iconImgSeventeen, setIconImgSeventeen] = useState(iconSeventeen); 
    const [toggle, setToggle] = useState(false);
    /*
    useEffect(() => {
      if(posts.length>25)
      {
        console.log("been here");
        setX(posts);
      }
      
    }, [])
    */
    
    useEffect(() => {

      async function fetchData() {
        // You can await here
        await dispatch(getPosts());
        await setTimeLeft(0);
        // ...
      }
      fetchData();
      
      
    }, [])

    

    useEffect(() => {
    
      handleSubmit();
    })
  useEffect(() => {
    if(timeLeft===0){
       console.log("TIME LEFT IS 0");
       setTimeLeft(null);
       
       console.log(posts);
       posts.map((post) => {  if(post.CoordinatesLong != undefined && post.CoordinatesLat != undefined) {setMarkerList((state)=>[
        ...state,
        {lat3: parseFloat(post.CoordinatesLongThree), long3: parseFloat(post.CoordinatesLatThree), lat2: parseFloat(post.CoordinatesLongTwo), long2: parseFloat(post.CoordinatesLatTwo),lat: parseFloat(post.CoordinatesLong), long: parseFloat(post.CoordinatesLat), projectNumber: post.ProjectNUmber, selectedFile: post.selectedFile, unWorldGoals: post.UNGoals, ID: post._id, title: post.title}
      ])}});

      

     
      const urlID = window.location.href.split("/")[6];
      if(urlID)
      {
       
        const singleCoor =  posts.filter((post) => post._id == urlID)[0];
        setSelecteMarker(singleCoor);
        
      }
      
      
      
    }

    console.log(markerList);

    console.log(timeLeft);
    
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
    
  

let val = selecteMarker;


    
  const handleShowProject = (coor) => {
    console.log(coor);
    console.log("here");

  }
  const handleSubmit = () =>{

    console.log("posts?.length: ", posts?.length);
    let number = posts?.length;
    //props.onSubmitTwo(number);
    
  }
 

  console.log("full list ", markerList);

  const ClosePost = () => {

    setSelecteMarker(false);
    history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${''}`);
        

  }


  useEffect(() => {
  
    //dispatch(getPost(window.location.href.split("/")[6]));
    if(window.location.href.split("/")[6]){
      console.log("dispatch", window.location.href.split("/")[6]);
  
    }
    
 
}, [window.location.href.split("/")[6]]);
    


  const iconTestTwo = (coorUn) => {

    console.log("iconTestTwo", coorUn);

    if(coorUn == 'No Poverty')
    {
      return iconImg;
    }



    else if(coorUn == undefined || coorUn == '')
    {
      
      return iconImgNo;
      
     
    }

     
    
 


    else if('Zero Hunger' == coorUn )
    {
      
      return iconImgTwo
      
     
    }

   

    else if('Good Health and Well-Being' == coorUn || coorUn == 'Health and Well-Being')
    {
      
      return iconImgThree
     

    }

   

    else if('Gender Equality' == coorUn )
    {
      return iconImgFive
     
      
    }
    else if('Clean Water and Sanitation' == coorUn )
    {
      return iconImgSix   
    }
    
    else if('Decent Jobs and Economic Growth'== coorUn )
    {
      return iconImgEight
     

    }
    else if('Reduced Inequality' == coorUn || 'Less Inequality' == coorUn || coorUn=='Reduced Inequalities' )
    {
      return iconImgTen
    
    }

    
    else if('Responsible Consumption and Production' == coorUn)
    {
      return iconImgTwelve
     
    }
    else if('Climate Action' == coorUn)
    {
      return iconImgThirteen
    }
    else if('Peace Justice and Strong Institutions' == coorUn || coorUn == 'Peace, Justice and Strong Institutions')
    {
      return iconImgSixteen
     
    }
    else if('Partnerships for the Goals' == coorUn || coorUn == 'Partnership for Action' )
    {
      return iconImgSeventeen
     
 
    }

    else if('Quality of Education' == coorUn )
    {
      return iconImgFour
    
      
    }

    else if('Affordable and Clean Energy' == coorUn || 'Affordable and Clean Water' == coorUn )
    {
      return iconImgSeven
      
     
    }


    else if('Industry Innovation and Infrastructure' == coorUn || 'Industri Innovation and Infrastructure' == coorUn)
    {
      return iconImgNine
      
     
    }


    else if('Sustainable Cities and Communities' == coorUn)
    {
      return iconImgEleven
      
  
    }
    else if('Life Below Water' == coorUn )
    {
      return iconImgFourteen
      
     
    }

    else if('Life On Land' == coorUn)
    {
      return iconImgFifteen
     
      
    }
    else{
      return iconImgNo

    }
    

  }
  useEffect(() => {
  
  const idFromUrl = window.location.href.split("/")[6];
  const newTag = window.location.href.split("/")[5].split("=")[2].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");
  const newSearch =  window.location.href.split("/")[5].split("=")[1].split("&")[0].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");
       
  if(!idFromUrl && newTag=='none' && newSearch == 'none'){
    ClosePost();
    history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${''}`);

  }
   
  
  }, [window.location.href])
  console.log("NavbarAdminBtn", dataForMap);
    
  const handleChangeMap = () => {
    setSelecteMap(!selecteMap);

  }
    return (
      <>
      {disabled && 
      <>
    
      {!selecteMarker && 
      <>
      {!props.dataForMap && <>
        <p className='MapChangeText' onClick={handleChangeMap} onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)}>Change Map</p>
       
      {toggle &&
            <div >
             <img src={MapChangeBtnHover} className='MapChangeHover'/>
              </div>}
              </>
              }
      
      
      </>
      }

<div className='mapText'> Read about Engineers Without Borders - Denmark's various projects. Push the "pins" on the map or search by thematic areas, UN Sustainable Development Goals, Project Number or Country
        </div>

        <GoogleMap zoom={2} center={center} mapContainerClassName="map-container">
        {markerList.map(coor => 
            <>
           <Marker position={{ lat: coor.lat, lng: coor.long }} title={coor.projectNumber} onClick={() => {setSelecteMarker(coor); history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${ coor.ID||''}`); setSelecteMarkerBool(true); }} options={{
            icon: iconTestTwo(coor.unWorldGoals),
           }}/>
          
           </>
          )}

      

       
             
       
        <div>
        {!posts?.length && <div className='AwaitSign'><CircularProgress color="secondary" />
        <CircularProgress color="success" />
        <CircularProgress color="inherit" /></div>}
        </div>
        
        
         
        </GoogleMap>

        {selecteMarker &&
        <>
         <div onClick={ClosePost} className='crossMap' >
            <img loading='lazy' src={crossBtn} width="24" height="24" />
        </div>
       
        
        <iframe loading="lazy" className='pdfFileMap' src={'https://storage.googleapis.com/ewbdk32/undefined/' + selecteMarker.selectedFile}  width="78%" height="120%" />
        </>
      }

      {selecteMap && <iframe className='MapTwo' src="https://www.google.com/maps/d/embed?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ehbc=2E312F" width="640" height="480"></iframe>}
   
    </>

  }
  </>
  )
  
}



/*
        <img src={MapChangeBtn} className='MapChangeBtn' }/>
  <iframe className='MapTwo' src="https://www.google.com/maps/d/embed?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ehbc=2E312F" width="640" height="480"></iframe>
       

      {markerList.map(coortwo => 
            <>
           <Marker position={{ lat: coortwo.lat2, lng: coortwo.long2 }} title={coortwo.projectNumber} onClick={() => {setSelecteMarker(coortwo); history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${ coortwo.ID||''}`); setSelecteMarkerBool(true); }} options={{
            icon: iconTestTwo(coortwo.unWorldGoals),
           }}/>
          
           </>
          )}


            {markerList.map(coorthree => 
            <>
           <Marker position={{ lat: coorthree.lat3, lng: coorthree.long3 }} title={coorthree.projectNumber} onClick={() => {setSelecteMarker(coorthree); history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/${ coorthree.ID||''}`); setSelecteMarkerBool(true); }} options={{
            icon: iconTestTwo(coorthree.unWorldGoals),
           }}/>
          
           </>
          )}

  */