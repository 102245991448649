
import { FETCH_POST, START_LOADING, END_LOADING, FETCH_ALL, CREATE, UPDATE, DELETE, LIKE,FETCH_BY_SEARCH } from '../constants/actionTypes';

import * as api from '../api/index.js';

export const getPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
   
    const { data: { data} } = await api.fetchPosts();

    console.log("getPosts",data );

    dispatch({ type: FETCH_ALL, payload: { data} });
    dispatch({ type: END_LOADING });
   
  } catch (error) {
    console.log(error);
  }
};


export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchPost(id);

    dispatch({ type: FETCH_POST, payload: { post: data } });
  } catch (error) {
    console.log(error);
  }
};


export const createPost = (post) => async (dispatch) => {
  try {
    const { data } = await api.createPost(post);
    console.log("from create post: ", data);
    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};



export const updatePost = (id, post) => async (dispatch) => {
  try {
    post._id = id;
    const { data } = await api.updatePost(id, post);
    console.log('from update function ', post);
    dispatch({ type: UPDATE, payload: post });
  } catch (error) {
    console.log(error.message);
  }
};

export const likePost = (id) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id);

    dispatch({ type: LIKE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data } } = await api.fetchPostsBySearch(searchQuery);
    console.log("getPostsBySearch", data);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};