import React,{useState} from 'react';
import './Footer.css';
import logoButton from '../../img/logoTwo.png';

function Footer() {
const [isShown, setIsShown] = useState(false);





  return (
    <>
    
     <span className='FooterBox'>
         <p className='FooterBoxTextOne'> Ingeniører Uden Grænser/ Engineers Without Borders Denmark</p>
       <p className='FooterBoxTextTwo'>Office: +4570274006/ Email: info@iug.dk</p>

      </span>
      


    {isShown && (
          <div className='FooterImgeHover'>
            To IUG.dk
          </div>
        )}


    </>
    
  )
}

export default Footer


/*
  <div className='logoTwo'>
        <img loading="lazy" src={logoButton}  width="150px" height="490%"></img>
      </div>
      
    <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <a href="https://iug.dk/">
      <div className='logoTwo'>
        <img loading="lazy" src={logoButton}  width="150px" height="550%"></img>
      </div>
      </a>
    </div>  

    <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <a href="https://iug.dk/">
      <div className='logoTwoTwo'>
        <img loading="lazy" src={logoButton}  width="150px" height="550%"></img>
      </div>
      </a>
    </div>  

    <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <a href="https://iug.dk/">
      <div className='logoTwoOne'>
        <img loading="lazy" src={logoButton}  width="150px" height="550%"></img>
      </div>
      </a>
    </div>  


    
*/