import React from 'react'

import userGuide from '../../LogoBox/Userguide.pdf';
import './Construction.css';
import previousBtn from './img/back.png';
function Construction() {
  return (
    <div>
      <a href='/posts'>
        <img src={previousBtn} className='previousBtn'></img>
      </a>

      <embed src={userGuide} width="700px" height="1400px" className='userGuidePDF' />
    </div>
  )
}

export default Construction