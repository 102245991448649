import React,{useState, useEffect,useRef} from 'react'
import Navbar from '../Navbar/Navbar';
import axios from 'axios';

import { TextField, Button, Typography, Paper } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, updatePost } from '../../actions/posts';
import { getPosts } from '../../actions/posts';
import SideMenu from '../SideMenu/SideMenu';
import SearchMenu from '../SearchMenu/SearchMenu';
import CheckBox from '../CheckBox/CheckBox';
import '../Footer/Footer.css';
import moment from "moment";
import './UploadProject.css';
import checkBox from '../../img/Vector.png';

import Ellipse from './img/Ellipse.png';

import statsBtn from './img/statsBtn.png';
import Group from './img/Btms.png';
import UploadToDb from './img/UploadToDb.png';

import Line from './img/Line.png';
import useStyles from '../Form/style';

import playBtn from './img/bi_collection-play.png';




import OpenFileExplorer from './img/FindProjectPDF.png';
import {FormControl, InputLabel, Select,MenuItem } from '@mui/material';

import succesIcon from './img/clarity_success-standard-line.png';

import iconOne from '../../LogoBox/1Afskaf_fattigdom.PNG';
import iconTwo from '../../LogoBox/2Stop_sult.PNG';
import iconThree from '../../LogoBox/3Sundhed_og_trivsel.PNG';
import iconFour from '../../LogoBox/4_E-WEB-Goal-04.png';
import iconFive from '../../LogoBox/5Ligestilling_mellem_køn.PNG';
import iconSix from '../../LogoBox/6Rent_vand_sanitet.PNG';
import iconSeven from '../../LogoBox/7Baeredygtig_energi.PNG';
import iconEight from '../../LogoBox/8Anstaendige_jobs_.PNG';
import iconNine from '../../LogoBox/9_E-WEB-Goal-09.png';
import iconTen from '../../LogoBox/10Mindre_ulighed.PNG';
import iconEleven from '../../LogoBox/11SustainableCitiesAndCo.jpg'; 
import iconTwelve from '../../LogoBox/12Ansvarligt_forbrug.PNG';
import iconThirteen from '../../LogoBox/13_E-WEB-Goal-15.PNG';
import iconFourteen from '../../LogoBox/14_E-WEB-Goal-14.png'; 
import iconFifteen from '../../LogoBox/15_E-WEB-Goal-15.png';
import iconSixteen  from '../../LogoBox/16Fred.PNG';
import iconSeventeen from '../../LogoBox/17Partnerskab_for_handling.PNG'; 


import Pdf from '../../LogoBox/Userguide.pdf';

import UserGuide from './img/userGuideBtn.png';


import deleteBtn from './img/deleteBtn.png';
import posts from '../../reducers/posts';
import DisasterRelief from '../DisasterRelief/DisasterRelief';



function UploadProject({ currentId, setCurrentId, postObj }) {
  const [isShown, setIsShown] = useState(false);
  const [isShownFileExplorer, setIsShownFileExplorer] = useState(false);
  const [isShownTitle, setIsShownTitle] = useState(false);
  
  const [isShownPictureExplorer, setIsShownPictureExplorer] = useState(false);
  
  
  const {posts} = useSelector((state) => state.posts);
  const [numberOfProject, setNumberOfProject] = useState(posts?.length);
  const [isShownHover, setIsShownHover] = useState(false);
  const [isShownUploadToDb, setIsShownUploadToDb] = useState(false);
  const [succes, setSucces] = useState(false);
  const [toggleIconOne, setToggleIconOne] = useState(false);
  const [toggleIconTwo, setToggleIconTwo] = useState(false);
  const [toggleIconThree, setToggleIconThree] = useState(false);
  const [toggleIconFour, setToggleIconFour] = useState(false);
  const [toggleIconFive, setToggleIconFive] = useState(false);
  const [toggleIconSix, setToggleIconSix] = useState(false);
  const [toggleIconSeven, setToggleIconSeven] = useState(false);
  const [toggleIconEight, setToggleIconEight] = useState(false);
  const [toggleIconNine, setToggleIconNine] = useState(false);
  const [toggleIconTen, setToggleIconTen] = useState(false);
  const [toggleIconEleven, setToggleIconEleven] = useState(false);
  const [toggleIconTwelve, setToggleIconTwelve] = useState(false);
  const [toggleIconThirteen, setToggleIconThirteen] = useState(false);
  const [toggleIconFourteen, setToggleIconFourteen] = useState(false);
  const [toggleIconFifteen, setToggleIconFifteen] = useState(false);
  const [toggleIconSixteen, setToggleIconSixteen] = useState(false);
  const [toggleIconSeventeen, setToggleIconSeventeen] = useState(false);
  const [toggleDeleteBtnHoverText, setToggleDeleteBtnHoverText] = useState(false);
  const [activities, setActivities] = useState('');
  const [unWorldGoals, setUnWorldGoals] = useState('');
  const [checkboxArray, setcheckboxArray] = useState([]);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [country, setCountry] = useState('');
  const [fileName, setFileName] = useState('');
  const [edit, setEdit] = useState(false);
  const [numberoFToggle, setNumberoFToggle] = useState(true);
  const [fileNamePicture, setFileNamePicture] = useState('');
  const [postData, setPostData] = useState({ title:'', message:'', selectedFile:'', creator:'', tags:[], selectedFileIcon:'',UNGoals:'',
        Country:'',
        ProjectNUmber:'',
        SeventeenUNGoals:'',
        CoordinatesLat:'',
        CoordinatesLong:'',
        CoordinatesLatTwo:'',
        CoordinatesLongTwo:'',
        CoordinatesLatThree:'',
        CoordinatesLongThree:'',
        pdfFile:'',
        token:'' });
 // const post = useSelector((state) => (currentId ? state.posts.find((UNGoals) => UNGoals._id === currentId) : null));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState(null);
  const [adminToggle, setAdminToggle] = useState(false);

  const [isCheckedWater, setIsCheckedWater] = useState(false);
  const [isCheckedBuilding, setIsCheckedBuilding] = useState(false);
  const [isCheckedSustainable, setIsCheckedSustainable] = useState(false);
  const [isCheckedInformation, setIsCheckedInformation] = useState(false);
  const [isCheckedWaste, setIsCheckedWaste] = useState(false);
  
  const [isCheckedRisk, setIsCheckedRisk] = useState(false);

  const [countryCountryBoxTwo, setContryCountryBoxTwo] = useState(false);
  const [countryCountryBoxThree, setContryCountryBoxThree] = useState(false);
  
  const handlePdf = () => {
 
     setAdminToggle(true);
   }

  useEffect(() => {
    
     dispatch(getPosts());
     console.log("upload obj", postObj)
     setUnWorldGoals(postObj.UNGoals);
     //unWorldGoals = postObj.UNGoals;
     setCountry(postObj.Country);

     setcheckboxArray(postObj.tags)
     postData.title = postObj.title;

     setPostData({ ...postData, tags: postObj.tags });
     postData.Country = postObj.Country;
     
     postData.ProjectNUmber = postObj.ProjectNUmber;
     postData.CoordinatesLong = postObj.CoordinatesLong;
     postData.CoordinatesLat = postObj.CoordinatesLat;
     postData.CoordinatesLongTwo = postObj.CoordinatesLongTwo;
     postData.CoordinatesLatTwo = postObj.CoordinatesLatTwo;
     postData.CoordinatesLongThree = postObj.CoordinatesLongTree;
     postData.CoordinatesLatThree = postObj.CoordinatesLatThree;
     //postData.tags = postObj.tags;
     postData.selectedFile  = postObj.selectedFile;
     
     postData.UNGoals = postObj.UNGoals;

    if(postObj._id != 0)
    {
      setEdit(true);
    }

    if(numberOfProject < 40)
    {
      setNumberoFToggle(false);
    }
    

      if(postObj.tags.map(tag => {if(tag=='Water and Sanitation'){ setIsCheckedWater(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Building and Construction'){  setIsCheckedBuilding(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Sustainable Energy'){ setIsCheckedSustainable(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Information and Technology'){ setIsCheckedInformation(true);}}))
      {} 
      if(postObj.tags.map(tag => {if(tag=='Waste Management'){ setIsCheckedWaste(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Risk Reduction and Disaster Relief'){ setIsCheckedRisk(true);}}))
      {}
      else{}
     
     
    postData.pdfFile = postObj.selectedFile;
     if(postObj.selectedFile!==''&&postObj.selectedFile)
     {
      setFileName(postObj.selectedFile);
     }
     else{}


    if(postObj.tags.map(tag => {if(tag=='No Poverty'){ 
     
       setToggleIconOne(true);

      }}));
 
     
    
 
      if(postObj.tags.map(tag => {if(tag=='Zero Hunger'){ 
     
  
       setToggleIconTwo(true);
       
       
      
      }}));



    if(postObj.tags.map(tag => {if(tag=='Good Health and Well-Being'){ 
    
     
       
       setToggleIconThree(true);
      
      
      }}));
 
 
      if(postObj.tags.map(tag => {if(tag=='Gender Equality'){ 
    
 
       setToggleIconFive(true);
       
      
      }}));
      

      if(postObj.tags.map(tag => {if(tag=='Clean Water and Sanitation'){ 
    
    
       setToggleIconSix(true);  
    
      }}));
     
      if(postObj.tags.map(tag => {if(tag=='Decent Jobs and Economic Growth'){ 
    
    
       setToggleIconEight(true);
       
       
      
      }}));

      if(postObj.tags.map(tag => {if(tag=='Reduced Inequality'){ 
    
  
       setToggleIconTen(true);
       
      }}));

      if(postObj.tags.map(tag => {if(tag=='Responsible Consumption and Production'){ 
    
    
       setToggleIconTwelve(true);
 
      }}));

      if(postObj.tags.map(tag => {if(tag=='Climate Action'){ 
    
   
       setToggleIconThirteen(true);
       
      
      }}));

      if(postObj.tags.map(tag => {if(tag=='Peace Justice and Strong Institutions'){ 
    
   
       setToggleIconSixteen(true);
      
       
      }}));


      if(postObj.tags.map(tag => {if(tag=='Partnerships for the Goals'){ 
    
    
       setToggleIconSeventeen(true);
      }}));

      if(postObj.tags.map(tag => {if(tag=='Quality of Education'){ 
    
 
  
       setToggleIconFour(true);
      
     
      }}));
 
      if(postObj.tags.map(tag => {if(tag=='Affordable and Clean Energy'){ 
    
   
       setToggleIconSeven(true);
      }}));

      if(postObj.tags.map(tag => {if(tag=='Industry Innovation and Infrastructure'){ 
    
 
   
       setToggleIconNine(true);
      }}));
 
      if(postObj.tags.map(tag => {if(tag=='Sustainable Cities and Communities'){ 
    
 
       setToggleIconEleven(true);
       
      
       
      
      }}));
 
      if(postObj.tags.map(tag => {if(tag=='Life On Land'){ 
    
   
       setToggleIconFifteen(true);
      
      
       
      }}));
    

    
    
  }, [])
  useEffect(() => {
    
    
    console.log("upload obj two", postObj)
    
   
   
 })

  
  
 

  useEffect(() => {
    if(timeLeft===0){
       console.log("TIME LEFT IS 0");
       setTimeLeft(null);
       setSucces(false);

    }
    
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const searchIn = useRef(null);
  
  const getCurrentDate = (separator='') =>{
    
    return moment().format("DD-MM-YYYY hh:mm:ss")
    }

  const handleChangeActivities = (event) => {

    setActivities(event.target.value);
    
    
  }
  
  const handleChangeUnWorldGoals = (event) => {

    
    if(event.target.value == 'No Poverty')
    {
      setToggleIconOne(true);
     
    }

    
   


    else if('Zero Hunger' ==event.target.value )
    {
      
      setToggleIconTwo(true);
      
      
     
    }

    else if('Good Health and Well-Being' ==event.target.value || event.target.value == 'Health and Well-Being')
    {
      
      setToggleIconThree(true);
     
     

    }


    else if('Gender Equality' ==event.target.value )
    {
      setToggleIconFive(true);
      
     
      
    }
    else if('Clean Water and Sanitation' == event.target.value )
    {
      setToggleIconSix(true);  
      
    }
    
    else if('Decent Jobs and Economic Growth'== event.target.value )
    {
      setToggleIconEight(true);
      

    }
    else if('Reduced Inequality' == event.target.value || 'Less Inequality' == event.target.value || event.target.value=='Reduced Inequalities' )
    {
      setToggleIconTen(true);
     
    }
    else if('Responsible Consumption and Production' == event.target.value)
    {
      setToggleIconTwelve(true);
     
     
    }
    else if('Climate Action' == event.target.value)
    {
      setToggleIconThirteen(true);
     
     
    }
    else if('Partnerships for the Goals' == event.target.value || event.target.value == 'Partnership for Action' )
    {
      setToggleIconSeventeen(true);
      
     
     
 
    }

    else if('Quality of Education' == event.target.value )
    {
      setToggleIconFour(true);
      
    
      
    }

    else if('Affordable and Clean Energy' ==event.target.value || 'Affordable and Clean Water' == event.target.value )
    {
      setToggleIconSeven(true);
     
     
    }


    else if('Industry Innovation and Infrastructure' == event.target.value || 'Industri Innovation and Infrastructure' == event.target.value)
    {
      setToggleIconNine(true);
      
     
    }


    else if('Sustainable Cities and Communities' == event.target.value)
    {
      setToggleIconEleven(true);
      
     
  
    }
    else if('Life Below Water' == event.target.value )
    {
      setToggleIconFourteen(true);
      
      
     
    }

    else if('Life On Land' == event.target.value)
    {
      setToggleIconFifteen(true);
      
      
    }

    else if('Peace Justice and Strong Institutions' == event.target.value)
    {
      setToggleIconSixteen(true);
      
      
    }
    else{


    }
    
    //const fileUploaded =event.target.files[0];
    /*
    const newFile = new File(["../../LogoBox/1Afskaf_fattigdom.PNG"], "test");
    console.log(newFile);

    //var url = URL.createObjectURL('../../LogoBox/1Afskaf_fattigdom.PNG');

    const reader = new FileReader();
  
    reader.readAsDataURL(newFile);

    reader.onload = () => {
      console.log('called: ', reader);
     
      setPostData({ ...postData, SeventeenUNGoals: reader.result });
    }
    
*/
   
    //setFileName(fileUploaded.name);
    
       setUnWorldGoals(event.target.value); 
     
       setPostData({ ...postData, UNGoals: event.target.value });
       setcheckboxArray((prevActivities) => [
        ...prevActivities,
        event.target.value
        ,
        ])
  

    


    
    
  }

  const handleChangeCountry = async(event) => {
    if(country != '')
    {
      await handleDeleteCheckboxChangeInput(country);
    }
    
    await setCountry(event.target.value);
    await setPostData({ ...postData, Country: event.target.value });
    setcheckboxArray((prevActivities) => [
      ...prevActivities,
      event.target.value
      ,
  ])
    //console.log(getCurrentDate())
  }


  const handleChangeCountryThree = async(event) => {
    if(country != '')
    {
      await handleDeleteCheckboxChangeInput(countryCountryBoxThree);
    }
    
    await setContryCountryBoxThree(event.target.value);
    await setPostData({ ...postData, CountryThree: event.target.value });
    setcheckboxArray((prevActivities) => [
      ...prevActivities,
      event.target.value
      ,
  ])
    //console.log(getCurrentDate())
  }

  const handleChangeCountryTwo = async(event) => {
    if(country != '')
    {
      await handleDeleteCheckboxChangeInput(countryCountryBoxTwo);
    }
    
    await setContryCountryBoxTwo(event.target.value);
    await setPostData({ ...postData, CountryTwo: event.target.value });
    setcheckboxArray((prevActivities) => [
      ...prevActivities,
      event.target.value
      ,
  ])
    //console.log(getCurrentDate())
  }
  /*
   useEffect(() => {
      if (post) setPostData(post);
  }, [post]);
 */

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      let number = numberOfProject;
      if(postObj._id == 0)
      {
        number =  numberOfProject+1;
      }
      
      await setNumberOfProject(number);

      await handleClickUp();

      setTimeLeft(5);
      
     
      succesHandler();
      
      
      if (postObj._id === 0) {
        dispatch(createPost(postData));
        clear();
      } else {
        console.log("from upload, else ", true)
        console.log("uploaded from here data; ", postData);
        dispatch(updatePost(postObj._id, postData));
        clear();
      }
     


      
    };


  const succesHandler = () =>{
    setSucces(true);
  } 

  const clear = () => {
    if(postObj._id == 0)
    {

      setCurrentId(0);
      setPostData({ creator: '', title: '', message: '', tags: '', selectedFile: '', selectedFileIcon: '',
      UNGoals: '',
      Country: '',
      ProjectNUmber: '',
      CoordinatesLat: '',
      CoordinatesLong: '',
      CoordinatesLatTwo: '',
      CoordinatesLongTwo: '',
      CoordinatesLatThree: '',
      CoordinatesLongThree: '',
      pdfFile: '' });
      setActivities('');
      setUnWorldGoals('');
      setcheckboxArray([]);
      setCountry('');
      setFileName('');

      
      setToggleIconOne(false);
      setToggleIconTwo(false);
      setToggleIconThree(false);
      setToggleIconFour(false);
      setToggleIconFive(false);
      setToggleIconSix(false);
      setToggleIconSeven(false);
      setToggleIconEight(false);
      setToggleIconNine(false);
      setToggleIconTen(false);
      setToggleIconEleven(false);
      setToggleIconTwelve(false);
      setToggleIconThirteen(false);
      setToggleIconFourteen(false);
      setToggleIconFifteen(false);
      setToggleIconSixteen(false);
      setToggleIconSeventeen(false);

      setIsCheckedWater(false);
      setIsCheckedBuilding(false);
      setIsCheckedSustainable(false);
      setIsCheckedInformation(false);
      setIsCheckedWaste(false);
      setIsCheckedRisk(false);

    }


    };



     // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputPicture = React.useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = eventOne => {
    hiddenFileInput.current.click();
  };

  const handleClickPicture = eventTwo => {
    hiddenFileInputPicture.current.click();
  };
    // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = (e) => {

    console.log('File info working!')
    console.log(e.target.files[0]);
    const formData = new FormData(); 
    //FILE INFO NAME WILL BE "my-image-file"
    formData.append('imgCollection', e.target.files[0], e.target.files[0].name);
    setImage(formData);

    const fileUploaded = e.target.files[0];
    setFileName(fileUploaded.name);


    setPostData({ ...postData, selectedFile: fileUploaded.name });


    //convertToBase64(fileUploaded)
    //console.log(fileUploaded.name);
  };

  const handleChangeIcon = eventOne => {
    const fileUploaded = eventOne.target.files[0];
    console.log("fileUploaded:", fileUploaded);
    setFileName(fileUploaded.name);
    convertToBase64Icon(fileUploaded)
    console.log(fileUploaded.name);
  };


  const convertToBase64 = (file) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)


    reader.onload = () => {
      console.log('called: ', reader)
      setPostData({ ...postData, pdfFile: reader.result });
    }
  }
  const convertToBase64Icon = (file) => {
    console.log("file; ", file);
    const reader = new FileReader()

    reader.readAsDataURL(file)


    reader.onload = () => {
      console.log('called: ', reader)
      setPostData({ ...postData, SeventeenUNGoals: reader.result });
    }
  }


    // to handle the user-selected file 
    const handleChangePicture = eventTwo => {
      const fileUploadedPicture = eventTwo.target.files[0];
      setFileNamePicture(fileUploadedPicture.name);
      convertToBase64Picture(fileUploadedPicture);

      const selectedFileIcon = fileNamePicture;
      console.log(selectedFileIcon);
      

      

      //console.log("from handleChangePicture: ", fileUploadedPicture);
    };
   
      

    const convertToBase64Picture = (file) => {
      const reader = new FileReader();
  
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        console.log('called: ', reader);
       
        setPostData({ ...postData, selectedFileIcon: reader.result });
      }
    }

    
    useEffect(() => {
      console.log("useEffect: ",checkboxArray);
      setPostData({ ...postData, tags: checkboxArray });
    
    },[checkboxArray]);
  
    const handleCheckboxChange = (event) =>{

     
      

      let tempId = event.currentTarget.id;
      let tempValue = event.target.value;
      if(tempValue == 'Water and Sanitation')
      {
         setIsCheckedWater(!isCheckedWater);
      }
      if(tempValue == 'Building and Construction')
      {
         setIsCheckedBuilding(!isCheckedBuilding);
      }
 
 
      if(tempValue == 'Sustainable Energy')
      {
         setIsCheckedSustainable(!isCheckedSustainable);
      }
 
 
      if(tempValue == 'Information and Technology')
      {
         setIsCheckedInformation(!isCheckedInformation);
      }
 
 
      if(tempValue== 'Waste Management')
      {
         setIsCheckedWaste(!isCheckedWaste);
      }
 
 
      if(tempValue == 'Risk Reduction and Disaster Relief')
      {
         setIsCheckedRisk(!isCheckedRisk);
      }
      else{
        
      }
     
      if(event.target.checked)
      {
        console.log("checked: ", event.target.checked);
      }
      else{
        console.log("checked: ", event.target.checked);
        handleDeleteCheckboxChangeInput(tempValue);

      
        
      }


      if(tempValue != undefined && event.target.checked != false)
      {
          setcheckboxArray((prevActivities) => [
            ...prevActivities,
            tempValue
            ,
        ])
        
      }
      else if (tempId != undefined)
      { 
        console.log("from else if: ",tempId)
        setcheckboxArray((prevActivities) => [
          ...prevActivities,
          tempId
          ,
      ])
      }
      //setcheckboxArray.push(event.target.value)
      console.log("handleChange: ",checkboxArray);
      
     
    }

    const handleDeleteCheckboxChangeInput = (CheckboxChangeToDelete) => setcheckboxArray(checkboxArray.filter((checkboxArray) => checkboxArray !== CheckboxChangeToDelete));


    const handleDeleteCheckboxChange = (event) => {
      let tempId = event.currentTarget.id;
      let tempValue = event.target.value;

      if(tempValue != undefined)
      {
        handleDeleteCheckboxChangeInput(tempValue);
      }
      else if (tempId != undefined)
      { 
        handleDeleteCheckboxChangeInput(tempId);
      }

      console.log("handleChange: ",checkboxArray);

    }

    const handleToggleIconOne = (event) => {
      setToggleIconOne(!toggleIconOne);
      let id="No Poverty"
      handleDeleteCheckboxChangeInput(id);
      

      
    }

    const handleToggleIconTwo = () => {
      setToggleIconTwo(!toggleIconTwo);
      let id="Zero Hunger"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconThree = () => {
      setToggleIconThree(!toggleIconThree);
      let id="Good Health and Well-Being"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconFour = () => {
      setToggleIconFour(!toggleIconFour);
      let id="Quality Education"
      handleDeleteCheckboxChangeInput(id);
    }
  

        const handleToggleIconFive = () => {
      setToggleIconFive(!toggleIconFive);
      let id="Gender Equality"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconSix = () => {
      setToggleIconSix(!toggleIconSix);
      let id="Clean Water and Sanitation"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconSeven = () => {
      setToggleIconSeven(!toggleIconSeven);
      let id="Affordable and Clean Energy"
      handleDeleteCheckboxChangeInput(id);
    }
  
        const handleToggleIconEight = () => {
      setToggleIconEight(!toggleIconEight);
      let id="Decent Work and Economic Growth"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconNine = () => {
      setToggleIconNine(!toggleIconNine);
      let id="Industry, Innovation and Infrastructure"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconTen = () => {
      setToggleIconTen(!toggleIconTen);
      let id="Reduced Inequalities"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconEleven = () => {
      setToggleIconEleven(!toggleIconEleven);
      let id="Sustainable Cities and Communities"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconTwelve = () => {
      setToggleIconTwelve(!toggleIconTwelve);
      let id="Responsible Consumption and Production"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconThirteen = () => {
      setToggleIconThirteen(!toggleIconThirteen);
      let id="Climate Action"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconFourteen = () => {
      setToggleIconFourteen(!toggleIconFourteen);
      let id="Life Below Water"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconFifteen = () => {
      setToggleIconFifteen(!toggleIconFifteen);
      let id="Life On Land"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconSixteen = () => {
      setToggleIconSixteen(!toggleIconSixteen);
      let id="Peace Justice and Strong Institutions"
      handleDeleteCheckboxChangeInput(id);
    }
  
    const handleToggleIconSeventeen = () => {
      setToggleIconSeventeen(!toggleIconSeventeen);
      let id="Partnerships for the Goals"
      handleDeleteCheckboxChangeInput(id);
    }

      
    const handleToggleDeleteBtnHoverText = () => {
      setToggleDeleteBtnHoverText(!toggleDeleteBtnHoverText);
    }


    
    
    const [array, setArray] = useState([])

    const handleDelete = () => {
      window.location.reload();

      /*
      setToggleIconOne(false);
      setToggleIconTwo(false);
      setToggleIconThree(false);
      setToggleIconFour(false);
      setToggleIconFive(false);
      setToggleIconSix(false);
      setToggleIconSeven(false);
      setToggleIconEight(false);
      setToggleIconNine(false);
      setToggleIconTen(false);
      setToggleIconEleven(false);
      setToggleIconTwelve(false);
      setToggleIconThirteen(false);
      setToggleIconFourteen(false);
      setToggleIconFifteen(false);
      setToggleIconSixteen(false);
      setToggleIconSeventeen(false);

      async function iconsDelete (){

       
        const listeOfIcons = []

        function getIndex(value, arr) {
          for(var i = 0; i < arr.length; i++) {
              if(arr[i] === value) {
                  return i;
              }
          }
          return -1;
      }

      let indexOne;
      let indexTwo;
      let indexThree;
        if(toggleIconOne)
        {
          indexOne = getIndex("No Poverty", checkboxArray)
        }
        if(toggleIconTwo)
        {
          indexTwo = getIndex("Zero Hunger", checkboxArray)
        }
        if(toggleIconThree)
        {
          indexThree = getIndex("Good Health and Well-Being", checkboxArray)
        }
        
        async function GFG_Fun(event) {
          console.log("indexTwo: ",indexOne);
          let remove = ["No Poverty", "Zero Hunger", "Good Health and Well-Being"];
          console.log("indexAll: ",remove);
          event.target.checked = false;
           
          for (var i = 0; i < remove.length; i++)
          {
            console.log("index{i}: ",remove[i]);
            await  setcheckboxArray(checkboxArray.filter((checkboxArray) => checkboxArray !== remove[i]));
             
             
          }
        }
          GFG_Fun()

      

    
        }

      
      iconsDelete();

      */
     
     
      

    }


    const handleNoPoverty = (event) =>{
 
      //console.log(event.currentTarget.id);
      //handleToggleIconOne();
      //handleAddChip(event.currentTarget.id);
  
      
      //handleWaterToggle();
    
    }
  
    const [image, setImage] = useState(null);
    const handleClickUp = () => {
      axios.post('https://iugserver.wn.r.appspot.com/image-upload', image).then(res => {
      console.log('Axios response: ', res)
    })
    

    }

    const getFileInfo = (e) => {
      //NOTE THE ADDITION OF 'e' PARAMETER
         console.log('File info working!')
         console.log(e.target.files[0]);
         const formData = new FormData(); 
         //FILE INFO NAME WILL BE "my-image-file"
         formData.append('imgCollection', e.target.files[0], e.target.files[0].name);
         setImage(formData);
     
       }


  
  
  return (
    <>
    <div className='background'>

            
       
      
          
        <div className='UploadProjectBox'>
        
        

          <div onClick={handlePdf}>
          <a href={Pdf} without rel="noopener noreferrer" target="_blank">
            <img loading="lazy" src={UserGuide} className='userGuide'/>
          </a>
          </div>
        
       

        


        {succes &&
       <>
         <img loading="lazy" src={succesIcon} className='succes'/>
          <p className='succesText'>{edit ?  'Project Updated!': 'Project Uploaded!'}  {timeLeft}</p>
          </>
         }
        

     
        <div className='UploadProjectTitle' onMouseEnter={() => setIsShownTitle(true)} onMouseLeave={() => setIsShownTitle(false)}>{edit ?  'Edit Project' : 'Upload Project' }</div>
        
        {isShownTitle && (
            <div className='UploadProjectTitleHover'>
              <p>Project files ought to be in pdf format because of space restrictions on the server</p>

              <p>In MsWord save your file as a Pdf file</p>
            </div>
          )}
        
        <div className='LineOne'>
                <img loading="lazy" src={Line}/>
            </div>


        
        <div className='ProjectStatusTitle'>Activities</div>

          <div className='checkBoxOneAdmin'>
          
          <input type='checkbox' value='Water and Sanitation' className='checkboxSize' checked={isCheckedWater} onChange={handleCheckboxChange}/>
          </div>
          <div className='checkBoxOneTextAdmin'>Water and Sanitation</div>

          <div className='checkBoxTwoAdmin'>

          <input type='checkbox' checked={isCheckedBuilding} value='Building and Construction' className='checkboxSize' onChange={handleCheckboxChange}/>
        
          </div>
          <div className='checkBoxTwoTextAdmin'>Building and Construction</div>


          <div className='checkBoxThreeAdmin'>
          <input type='checkbox' checked={isCheckedSustainable} value='Sustainable Energy' className='checkboxSize' onChange={handleCheckboxChange}/>
        
          </div>
          <div className='checkBoxThreeTextAdmin'>Sustainable Energy</div>

          <div className='checkBoxFourAdmin'>
          <input type='checkbox' checked={isCheckedInformation} value='Information and Technology' className='checkboxSize' onChange={handleCheckboxChange}/>
        
          </div>
          <div className='checkBoxFourTextAdmin'>Information and Technology</div>

 


          <div className='checkBoxFiveAdmin'>
          <input type='checkbox' checked={isCheckedWaste} value='Waste Management' className='checkboxSize' onChange={handleCheckboxChange}/>
        
          </div>
          <div className='checkBoxFiveTextAdmin'>Waste Management</div>

          <div className='checkBoxSixAdmin'>
          <input type='checkbox' checked={isCheckedRisk} value='Risk Reduction and Disaster Relief' className='checkboxSize' onChange={handleCheckboxChange}/>
        
          </div>
          <div className='checkBoxSixTextAdmin'>Risk Reduction and Disaster Relief</div>

 

        

        <div className='BoxTwo'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Link Card Icon</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={unWorldGoals}
              label="UnWorldGoals"
              onChange={handleChangeUnWorldGoals}
              
            >
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='No Poverty'>1. No Poverty</MenuItem>
              <MenuItem value='Zero Hunger'>2. Zero Hunger</MenuItem>
              <MenuItem value='Good Health and Well-Being'>3. Good Health and Well-Being</MenuItem>
              <MenuItem value='Quality of Education'>4. Quality of Education</MenuItem>
              <MenuItem value='Gender Equality'>5. Gender Equality</MenuItem>
              <MenuItem value='Clean Water and Sanitation'>6. Clean Water and Sanitation</MenuItem>
              <MenuItem value='Affordable and Clean Energy'>7. Affordable and Clean Energy</MenuItem>
              <MenuItem value='Decent Jobs and Economic Growth'>8. Decent Jobs and Economic Growth</MenuItem>
              <MenuItem value='Industri Innovation and Infrastructure'>9. Industri Innovation and Infrastructure</MenuItem>
              <MenuItem value='Reduced Inequalities'>10. Reduced Inequalities</MenuItem>
              <MenuItem value='Sustainable Cities and Communities'>11. Sustainable Cities and Communities</MenuItem>
              <MenuItem value='Responsible Consumption and Production'>12. Responsible Consumption and Production</MenuItem>
              <MenuItem value='Climate Action'>13. Climate Action</MenuItem>
              <MenuItem value='Life Below Water'>14. Life Below Water</MenuItem>
              <MenuItem value='Life On Land'>15. Life On Land</MenuItem>
              <MenuItem value='Peace Justice and Strong Institutions'>16. Peace, Justice and Strong Institutions</MenuItem>
              <MenuItem value='Partnerships for the Goals'>17. Partnerships for the Goals</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='BoxThree'></div>
        
        <div className='BoxFour'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Country 1</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="Country 1"
              onChange={handleChangeCountry}
              
            >
             
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='Bangladesh'>Bangladesh</MenuItem>
              <MenuItem value='Benin'>Benin</MenuItem>
              <MenuItem value='Bhutan'>Bhutan</MenuItem>
              <MenuItem value='Bolivia'>Bolivia</MenuItem>
              <MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>
              <MenuItem value="Cameroon">Cameroon</MenuItem>
              <MenuItem value='Congo'>Congo</MenuItem>
              <MenuItem value='Denmark'>Denmark</MenuItem>
              <MenuItem value='Ethiopia'>Ethiopia</MenuItem>
              <MenuItem value='Gambia'>Gambia</MenuItem>
              <MenuItem value='Ghana'>Ghana</MenuItem>
              <MenuItem value='Haiti'>Haiti</MenuItem>
              <MenuItem value='India'>India</MenuItem>
              <MenuItem value='Ivory Coast'>Ivory Coast</MenuItem>
              <MenuItem value='Kenya'>Kenya</MenuItem>
              <MenuItem value='Liberia'>Liberia</MenuItem>
              <MenuItem value='Nigeria'>Nigeria</MenuItem>
              <MenuItem value='Palestine'>Palestine</MenuItem>
              <MenuItem value='Pakistan'>Pakistan</MenuItem>
              <MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>
              <MenuItem value='Somali Land'>Somali Land</MenuItem>
              <MenuItem value='South Sudan'>South Sudan</MenuItem>
              <MenuItem value='Togo'>Togo</MenuItem>
              <MenuItem value='Tanzania'>Tanzania</MenuItem>
              <MenuItem value='Zimbabwe'>Zimbabwe</MenuItem>
              <MenuItem value="Uganda">Uganda</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='CountryBoxTwo'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-CountryBoxTwo">Country 2</InputLabel>
            <Select
              labelId="demo-simple-select-label-CountryBoxTwo"
              id="demo-simple-select-CountryBoxTwo"
              value={countryCountryBoxTwo}
              label="Country 2"
              onChange={handleChangeCountryTwo}
              
            >
             
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='Bangladesh'>Bangladesh</MenuItem>
              <MenuItem value='Benin'>Benin</MenuItem>
              <MenuItem value='Bhutan'>Bhutan</MenuItem>
              <MenuItem value='Bolivia'>Bolivia</MenuItem>
              <MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>
              <MenuItem value="Cameroon">Cameroon</MenuItem>
              <MenuItem value='Congo'>Congo</MenuItem>
              <MenuItem value='Denmark'>Denmark</MenuItem>
              <MenuItem value='Ethiopia'>Ethiopia</MenuItem>
              <MenuItem value='Gambia'>Gambia</MenuItem>
              <MenuItem value='Ghana'>Ghana</MenuItem>
              <MenuItem value='Haiti'>Haiti</MenuItem>
              <MenuItem value='India'>India</MenuItem>
              <MenuItem value='Ivory Coast'>Ivory Coast</MenuItem>
              <MenuItem value='Kenya'>Kenya</MenuItem>
              <MenuItem value='Liberia'>Liberia</MenuItem>
              <MenuItem value='Nigeria'>Nigeria</MenuItem>
              <MenuItem value='Palestine'>Palestine</MenuItem>
              <MenuItem value='Pakistan'>Pakistan</MenuItem>
              <MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>
              <MenuItem value='Somali Land'>Somali Land</MenuItem>
              <MenuItem value='South Sudan'>South Sudan</MenuItem>
              <MenuItem value='Togo'>Togo</MenuItem>
              <MenuItem value='Tanzania'>Tanzania</MenuItem>
              <MenuItem value='Zimbabwe'>Zimbabwe</MenuItem>
              <MenuItem value="Uganda">Uganda</MenuItem>
            </Select>
          </FormControl>
        </div>


        <div className='CountryBoxThree'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-CountryBoxThree">Country 3</InputLabel>
            <Select
              labelId="demo-simple-select-label-CountryBoxThree"
              id="demo-simple-select-CountryBoxThree"
              value={countryCountryBoxThree}
              label="Country 3"
              onChange={handleChangeCountryThree}
              
            >
             
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='Bangladesh'>Bangladesh</MenuItem>
              <MenuItem value='Benin'>Benin</MenuItem>
              <MenuItem value='Bhutan'>Bhutan</MenuItem>
              <MenuItem value='Bolivia'>Bolivia</MenuItem>
              <MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>
              <MenuItem value="Cameroon">Cameroon</MenuItem>
              <MenuItem value='Congo'>Congo</MenuItem>
              <MenuItem value='Denmark'>Denmark</MenuItem>
              <MenuItem value='Ethiopia'>Ethiopia</MenuItem>
              <MenuItem value='Gambia'>Gambia</MenuItem>
              <MenuItem value='Ghana'>Ghana</MenuItem>
              <MenuItem value='Haiti'>Haiti</MenuItem>
              <MenuItem value='India'>India</MenuItem>
              <MenuItem value='Ivory Coast'>Ivory Coast</MenuItem>
              <MenuItem value='Kenya'>Kenya</MenuItem>
              <MenuItem value='Liberia'>Liberia</MenuItem>
              <MenuItem value='Nigeria'>Nigeria</MenuItem>
              <MenuItem value='Palestine'>Palestine</MenuItem>
              <MenuItem value='Pakistan'>Pakistan</MenuItem>
              <MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>
              <MenuItem value='Somali Land'>Somali Land</MenuItem>
              <MenuItem value='South Sudan'>South Sudan</MenuItem>
              <MenuItem value='Togo'>Togo</MenuItem>
              <MenuItem value='Tanzania'>Tanzania</MenuItem>
              <MenuItem value='Zambia'>Zambia</MenuItem>
              <MenuItem value='Zimbabwe'>Zimbabwe</MenuItem>
              <MenuItem value="Uganda">Uganda</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='LineTwo'>
                <img loading="lazy" src={Line}/>
            </div>


        <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
          <div className='CoordinatesTitle'>Coordinates</div>
          
            <input name='CoordinatesLat' value={postData.CoordinatesLat}   onChange={(e) => setPostData({ ...postData, CoordinatesLat: e.target.value })} className='BoxFiveLon' placeholder='  Longitude'/>
            <input name='CoordinatesLon' value={postData.CoordinatesLong}   onChange={(e) => setPostData({ ...postData, CoordinatesLong: e.target.value })} className='BoxFiveLat' placeholder='  Latitude'/>


            <input name='CoordinatesLatTwo' value={postData.CoordinatesLatTwo}   onChange={(e) => setPostData({ ...postData, CoordinatesLatTwo: e.target.value })} className='BoxFiveLon2' placeholder='  Longitude 2'/>
            <input name='CoordinatesLonTwo' value={postData.CoordinatesLongTwo}   onChange={(e) => setPostData({ ...postData, CoordinatesLongTwo: e.target.value })} className='BoxFiveLat2' placeholder='  Latitude 2'/>



            <input name='CoordinatesLatThree' value={postData.CoordinatesLatThree}   onChange={(e) => setPostData({ ...postData, CoordinatesLatThree: e.target.value })} className='BoxFiveLon3' placeholder='  Longitude 3'/>
            <input name='CoordinatesLonThree' value={postData.CoordinatesLongThree}   onChange={(e) => setPostData({ ...postData, CoordinatesLongThree: e.target.value })} className='BoxFiveLat3' placeholder='  Latitude 3'/>


        </div>
          {isShown && (
            <div className='CoordinatesHover'>
              Right click on Google Maps to see coordinates 
            </div>
          )}
        

        
        <input name='title' value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} className='BoxSix' placeholder='  Project Title'/>
        <input name='title' value={postData.creator} onChange={(e) => setPostData({ ...postData, creator: e.target.value })} className='BoxEkstra' placeholder='  Front image Title'/>
        

        <input name='ProjectNUmber' value={postData.ProjectNUmber} onChange={(e) => setPostData({ ...postData, ProjectNUmber: e.target.value })} className='BoxSeven' placeholder='  Project Number'/>
       

        
        <div className='LineThree'>
                <img loading="lazy" src={Line}/>
            </div>

            <div className='iconTitle'>
              UN World Goals
            </div>

            <div className='IconSubTitle'>
            Click the wanted goal(s) to add it to the search category

            </div>



            <div className='UNWorldGoalsIconsUploadSite'>
            {!toggleIconOne &&
         
              <div id="No Poverty" onClick={handleCheckboxChange}>
                <img loading="lazy" className='iconOneUpload' src={iconOne} onClick={handleToggleIconOne}/>
              </div>
            

            
            }
            {!toggleIconTwo &&


            <div id="Zero Hunger" onClick={handleCheckboxChange}>
                <img loading="lazy" src={iconTwo} ref={searchIn} className='iconTwoUpload' onClick={handleToggleIconTwo}/>
            </div>
            }
            {!toggleIconThree &&
            <div id="Good Health and Well-Being" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconThree} className='iconThreeUpload' onClick={handleToggleIconThree}/>
            </div>
            }
            {!toggleIconFour &&
            <div id="Quality Education" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconFour} className='iconFourUpload' onClick={handleToggleIconFour}/>
            </div>
            }
            {!toggleIconFive &&
            <div id="Gender Equality" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconFive} className='iconFiveUpload'  onClick={handleToggleIconFive}/>
            </div>
            }
            {!toggleIconSix &&
            <div id="Clean Water and Sanitation" onClick={handleCheckboxChange}>
             <img loading="lazy" src={iconSix} className='iconSixUpload'  onClick={handleToggleIconSix}/>
            </div>
            }
            {!toggleIconSeven &&
            <div id="Affordable and Clean Energy" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconSeven} className='iconSevenUpload' onClick={handleToggleIconSeven}/>
            </div>
            }
            {!toggleIconEight &&
            <div id="Decent Work and Economic Growth" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconEight} className='iconEightUpload'  onClick={handleToggleIconEight}/>
            </div>
            }
            {!toggleIconNine &&
            <div id="Industry, Innovation and Infrastructure" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconNine} className='iconNineUpload'  onClick={handleToggleIconNine}/>
            </div>
            }
            {!toggleIconTen &&
            <div id="Reduced Inequalities" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconTen} className='iconTenUpload' onClick={handleToggleIconTen}/>
            </div>
            }
            {!toggleIconEleven &&
            <div id="Sustainable Cities and Communities" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconEleven} className='iconElevenUpload' onClick={handleToggleIconEleven}/>
            </div>
            }
            {!toggleIconTwelve &&
            <div id="Responsible Consumption and Production" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconTwelve} className='iconTwelveUpload'  onClick={handleToggleIconTwelve}/>
            </div>
            }
            {!toggleIconThirteen &&
            <div id="Climate Action" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconThirteen} className='iconThirteenUpload' onClick={handleToggleIconThirteen}/>
            </div>
            }
            {!toggleIconFourteen &&
            <div id="Life Below Water" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconFourteen} className='iconFourteenUpload' onClick={handleToggleIconFourteen}/>
            </div>
            }
            {!toggleIconFifteen &&
            <div id="Life On Land" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconFifteen} className='iconFifteenUpload' onClick={handleToggleIconFifteen}/>
            </div>
            }
            {!toggleIconSixteen &&
            <div id="Peace Justice and Strong Institutions" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconSixteen} className='iconSixteenUpload' onClick={handleToggleIconSixteen}/>
            </div>
            }
            {!toggleIconSeventeen &&
            <div id="Partnerships for the Goals" onClick={handleCheckboxChange}>
              <img loading="lazy" src={iconSeventeen} className='iconSeventeenUpload' onClick={handleToggleIconSeventeen}/>
            </div>
            }

            </div>


            <div className='selectedSubUNGoals'>
              Selected UN World Goal(s)
            </div>

            <div className='selectedSubUNGoalsBox'>
            {toggleIconOne &&
          
              <img loading="lazy" src={iconOne} className='iconOneUploadSelected' onClick={handleToggleIconOne}/>
            
            }
            {toggleIconTwo &&
            <img loading="lazy" src={iconTwo} className='iconTwoUploadSelected' onClick={handleToggleIconTwo}/>
            }
            {toggleIconThree &&
            <img loading="lazy" src={iconThree} className='iconThreeUploadSelected' onClick={handleToggleIconThree}/>
            }
            {toggleIconFour &&
            <img loading="lazy" src={iconFour} className='iconFourUploadSelected' onClick={handleToggleIconFour}/>
            }
            {toggleIconFive &&
            <img loading="lazy" src={iconFive} className='iconFiveUploadSelected'  onClick={handleToggleIconFive}/>
            }
            {toggleIconSix &&
            <img loading="lazy" src={iconSix} className='iconSixUploadSelected'  onClick={handleToggleIconSix}/>
            }
            {toggleIconSeven &&
            <img loading="lazy" src={iconSeven} className='iconSevenUploadSelected' onClick={handleToggleIconSeven}/>
            }
            {toggleIconEight &&
            <img loading="lazy" src={iconEight} className='iconEightUploadSelected'  onClick={handleToggleIconEight}/>
            }
            {toggleIconNine &&
            <img loading="lazy" src={iconNine} className='iconNineUploadSelected'  onClick={handleToggleIconNine}/>
            }
            {toggleIconTen &&
            <img loading="lazy" src={iconTen} className='iconTenUploadSelected' onClick={handleToggleIconTen}/>
            }
            {toggleIconEleven &&
            <img loading="lazy" src={iconEleven} className='iconElevenUploadSelected' onClick={handleToggleIconEleven}/>
            }
            {toggleIconTwelve &&
            <img loading="lazy" src={iconTwelve} className='iconTwelveUploadSelected'  onClick={handleToggleIconTwelve}/>
            }
            {toggleIconThirteen &&
            <img loading="lazy" src={iconThirteen} className='iconThirteenUploadSelected' onClick={handleToggleIconThirteen}/>
            }
            {toggleIconFourteen &&
            <img loading="lazy" src={iconFourteen} className='iconFourteenUploadSelected' onClick={handleToggleIconFourteen}/>
            }
            {toggleIconFifteen &&
            <img loading="lazy" src={iconFifteen} className='iconFifteenUploadSelected' onClick={handleToggleIconFifteen}/>
            }
            {toggleIconSixteen &&
            <img loading="lazy" src={iconSixteen} className='iconSixteenUploadSelected' onClick={handleToggleIconSixteen}/>
            }
            {toggleIconSeventeen &&
            <img loading="lazy" src={iconSeventeen} className='iconSeventeenUploadSelected' onClick={handleToggleIconSeventeen}/>
            }


            </div>

 
            <div onMouseEnter={() => setToggleDeleteBtnHoverText(true)} onMouseLeave={() => setToggleDeleteBtnHoverText(false)}>
            <img loading="lazy" src={deleteBtn} className='deleteBtnSelectedSubUNGoals' onClick={handleDelete}/>

            </div>
            {toggleDeleteBtnHoverText &&
            <div className='removeSelectedSubUNGoals'>
               REMOVE SELECTED UN GOALS
            </div>
            }

            

       

        <div onMouseEnter={() => setIsShownFileExplorer(true)} onMouseLeave={() => setIsShownFileExplorer(false)}>
         <img loading="lazy" src={OpenFileExplorer} onClick={handleClick} className='OpenFileExplorer'/>

          <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}} 
            />
          <p className='OpenFileExplorerText'>Selected Project File: {fileName}</p>
         
        </div>
        {isShownFileExplorer && (
            <div className='FileExplorerHover'>
              Find projectfile(no max kB)
            </div>
          )}

    
         

        


          
          {numberoFToggle &&

          <div onMouseEnter={() => setIsShownHover(true)} onMouseLeave={() => setIsShownHover(false)}>
           
          <img loading="lazy" className='Ellipse' src={Ellipse}/>
            <div className='projects'>{numberOfProject}</div>

          </div>

          }

          
          {isShownHover && (
                      <div className='NumberOfProjectsHover'>
                        Total number can be seen after visiting 'All EWB-Dk Projects' page
                      </div>
                    )}

          <a href='https://gigahost.dk' target="_blank" rel="noopener noreferrer">
            <img loading="lazy" className='statsBtn' src={statsBtn}/>
          </a>
         


        <div className='LineFour'>
                <img loading="lazy" src={Line}/>
            </div>

        <div onMouseEnter={() =>setIsShownUploadToDb(true)} onMouseLeave={() => setIsShownUploadToDb(false)}>
          
            <button  type="submit" onClick={handleSubmit}>
             <img loading="lazy" src={UploadToDb} className='UploadToDb'/>
            </button>
        </div>
        {isShownUploadToDb && (
            <div className='UploadToDbHover'>
              Upload your projectfile to the database with the info on this side

            </div>
          )}

       
       
       </div>
      
   
       

    </div>
   
    </>
    
  )
}

export default UploadProject



/*

 <input name='pdfFile' value={postData.selectedFile} onChange={(e) => setPostData({ ...postData, selectedFile: e.target.value })} className='BoxEight' placeholder='  PDF input field'/>
       
    <div onClick={() => window.open("https://drive.google.com/uc?id=1Jh8PvxoBhoPDdChZWRqxR5Nvyubvzg-z")}>
          <img loading="lazy" src={playBtn} className='playBtn' />
        </div>



 <video width="640" height="480" controls>
          <source src={playVideo} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>


 <img loading="lazy" className='Ellipse' src={Ellipse}/>
            <div className='projects'>{numberOfProject}</div>

postData.pdfFile = postObj.pdfFile;
     if(postObj.pdfFile!==''&&postObj.pdfFile)
     {
      setFileName(postObj.pdfFile.substring(0, 20));
     }
     else{}


      <img loading="lazy" src={OpenFileExplorer} onClick={handleClick} className='OpenFileExplorer'/>

          <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}} 
            />
          <p className='OpenFileExplorerText'>Selected Project File: {fileName}</p>
 <div onMouseEnter={() => setIsShownPictureExplorer(true)} onMouseLeave={() => setIsShownPictureExplorer(false)}>
          
          <div className='OpenPictureExplorer' onClick={handleClickPicture}>
            Find Picture
          </div>

          <input
              type="file"
              ref={hiddenFileInputPicture}
              onChange={handleChangePicture}
              style={{display: 'none'}} 
             
            ></input>
          <p className='OpenPictureExplorerText'>Selected Picture File: {fileNamePicture}</p>
        </div>
        {isShownPictureExplorer && (
            <div className='OpenPictureExplorerHover'>
              Picture for the project link
              max 300 kb
            </div>
          )}




    <Paper className={classes.paper}>
    <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
      <Typography variant="h6">{currentId ? `Editing "${post.title}"` : 'Creating a Memory'}</Typography>
      
      <TextField name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
      <TextField name="message" variant="outlined" label="Message" fullWidth multiline rows={4} value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />
      <TextField name="tags" variant="outlined" label="Tags (coma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} />

      
      <p> Icon </p>
      <div className='UploadProjectCardIcon' onMouseEnter={() => setIsShownTitle(true)} onMouseLeave={() => setIsShownTitle(false)}>
        
      <div className={classes.fileInput}><FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} /></div>
      </div> 
      {isShownTitle && (
            <div className='UploadProjectCardIconHover'>
              <p>Project files ought to be in pdf format because of space restrictions on the server.</p>

              <p>The picture will be used in the link on the projects page.</p>

              <p>In MsWord save your file as a Pdf file.</p>
            </div>
          )}
      
          
      if(postObj.tags.map(tag => {if(tag=='Water and Sanitation'){ setIsCheckedWater(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Building and Construction'){  setIsCheckedBuilding(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Sustainable Energy'){ setIsCheckedSustainable(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Information and Technology'){ setIsCheckedInformation(true);}}))
      {} 
      if(postObj.tags.map(tag => {if(tag=='Waste Management'){ setIsCheckedWaste(true);}}))
      {}
      if(postObj.tags.map(tag => {if(tag=='Risk Reduction and Disaster Relief'){ setIsCheckedRisk(true);}}))
      {}
      else{}

      
      
      <p>Project pdf File</p>
      <div className={classes.fileInput}><FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFileIcon: base64 })} /></div>
      <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
      <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
    </form>
  </Paper>





 <Navbar />
      
        <a href="/"> 
            <div className='MapSideLinkGroup'>
                <img src={Group}/>
            </div>
          </a> 
      
        <SideMenu/>
        <SearchMenu/>


        <button onClick={handleClickUp}> Image Upload Tutorial Upload!</button>
        <input type="file" onChange={getFileInfo}/>


*/