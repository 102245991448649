import * as actionType from '../constants/actionTypes';

const authReducer = (state = { authData: null, users: [] }, action) => {
  switch (action.type) {

    case actionType.FETCH_ALL_USERS:
      return action.payload;

    case actionType.DELETE:
      return { ...state, users: state.users.filter((user) => user._id !== action.payload) };

    case actionType.AUTH:
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      localStorage.setItem("SavedToken", 'Bearer ' + { ...action?.data.token });
      

      return { ...state, authData: action.data, loading: false, errors: null };
    case actionType.LOGOUT:
      localStorage.clear();

      return { ...state, authData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default authReducer;