import React, {useState} from 'react'
import FoodSecurity from '../FoodSecurity/FoodSecurity'

function CapacityBuilding() {
  const [wasteToggle, setWasterToggle] = useState('Water and Sanitation');

  return (
    
    <FoodSecurity valueFromLink={wasteToggle}/>
  )
}

export default CapacityBuilding