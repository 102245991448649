import React, {useState, useRef} from 'react'
import './Search.css'

import searchBTN from './img/btn.png';

function Search(props) {

const searchIn = useRef(null);
const [isShown, setIsShown] = useState(false);
  
const sendData = (event) => {
  console.log(searchIn.current.value);
  props.onSubmit(searchIn.current.value);
  searchIn.current.value = "";

  
}

const handleKeyPress = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    sendData(e);
    
    
  }
};
  return (
    
        <div className='' onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
 
          <form>
          <input type="text" ref={searchIn} id='SearchBoxInputFieldID' className='SearchBoxInputField'onKeyDown={handleKeyPress} placeholder='   Search'>
            
            </input>
           <img src={searchBTN} className='btn2' id='btn2ID' onClick={sendData} ></img>
          </form>



         
          
        </div>
    
  )
}

export default Search


/** <div className='miniBegin'>
          <form>
          <input type="text" ref={searchIn} id='MiniSearchBoxInputFieldID' className='MiniSearchBoxInputField'onKeyDown={handleKeyPress} placeholder='   Search'>
            
            </input>
           <img src={searchBTN} className='Minibtn2' id='btn2ID' onClick={sendData} ></img>
          </form>
          </div> */