import './App.css';
import React from 'react';
import HomeIndex from './components/Home/Home';
import Water from './components/Water/Water';
import Environment from './components/Environment/Environment';
import FoodSecurity from './components/FoodSecurity/FoodSecurity';
import DisasterRelief from './components/DisasterRelief/DisasterRelief';
import Construction from './components/Construction/Construction';
import CapacityBuilding from './components/CapacityBuilding/CapacityBuilding';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import UploadProject from './components/UploadProject/UploadProject';
import PdfFile from './components/Posts/Post/PdfFile';
import Auth from './components/Auth/Auth';
import { Navigate } from 'react-router-dom';

function App() {
 

  return (
    <>

    

   
      <Routes>

        
        <Route path="*" element={<Navigate to='/posts' FoodSecurity /> }/>
        <Route path="/" element={<Navigate to='/posts' FoodSecurity /> }/>
        <Route path="/auth" element={<Auth/> }/>
        <Route path="/UploadProject" element={<UploadProject/> }/>
        <Route path="/Water" element={<Water/> }/>

        <Route path="/posts" element={<FoodSecurity/> }/>
        <Route path="/posts/search/:id" element={<DisasterRelief/>} />
        <Route path="/posts/search" element={<FoodSecurity/> }/>

        <Route path="/UserGuide" element={<DisasterRelief/> }/>
        <Route path="/PdfFile" element={<PdfFile/> }/>
        <Route path="/pdf" element={<Construction/> }/>
        <Route path="/CapacityBuilding" element={<CapacityBuilding/> }/>


      </Routes>

 
 

    </>
  );
}


export default App

//<a src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDax4lDkP6Q4U7R2Ta8XRdbSIVwM8_o75I&callback=initMap"></a>

/*
   <a href="https://www.google.com/maps/d/viewer?mid=1pe3t-w2532xe2EXeeqxTw4XD-I5ntCme&ll=8.524343052360214%2C3.117302500000008&z=3">
      <div className='map'>
        <img src={map}></img>
      </div>
    </a>

*/