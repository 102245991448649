import React,{useState, useEffect, lazy , Suspense} from 'react';
import useStyles from './style';
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core/';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import DeleteIcon from '@material-ui/icons/Delete';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import { likePost, deletePost } from '../../../actions/posts';
import PdfFile from './PdfFile';
import { useNavigate, useLocation } from 'react-router-dom';
import editBtn from './img/editYellowBtn.png';
import img from './img/img.png';
import Icon from '../../../LogoBox/2Stop_sult.PNG';
import open from './img/open.png';

import iconOne from '../../../LogoBox/1Afskaf_fattigdom.PNG';
import iconTwo from '../../../LogoBox/2Stop_sult.PNG';
import iconThree from '../../../LogoBox/3Sundhed_og_trivsel.PNG';
import iconFour from '../../../LogoBox/4_E-WEB-Goal-04.png';
import iconFive from '../../../LogoBox/5Ligestilling_mellem_køn.PNG';
import iconSix from '../../../LogoBox/6Rent_vand_sanitet.PNG';
import iconSeven from '../../../LogoBox/7Baeredygtig_energi.PNG';
import iconEight from '../../../LogoBox/8Anstaendige_jobs_.PNG';
import iconNine from '../../../LogoBox/9_E-WEB-Goal-09.png';
import iconTen from '../../../LogoBox/10Mindre_ulighed.PNG';
import iconEleven from '../../../LogoBox/12Ansvarligt_forbrug.PNG';
import iconTwelve from '../../../LogoBox/12Ansvarligt_forbrug.PNG';
import iconThirteen from '../../../LogoBox/13_E-WEB-Goal-15.PNG';
import iconFourteen from '../../../LogoBox/14_E-WEB-Goal-14.png';
import iconFifteen from '../../../LogoBox/15_E-WEB-Goal-15.png';
import iconSixteen from '../../../LogoBox/16Fred.PNG';
import iconSeventeen from '../../../LogoBox/17Partnerskab_for_handling.PNG';
import iconElevenRight from '../../../LogoBox/11SustainableCitiesAndCo.jpg';

import NoIcon from '../../../WorldGoals/UNWorldGoalsPic.PNG';
 

import './Post.css';

function Post(props) {
    const dispatch = useDispatch();
    const [isShown, setIsShown] = useState(false);
    const classes = useStyles();
    const [iconImg, setIconImg] = useState(''); 
    const [iconImgTwo, setIconImgTwo] = useState('');
    const [iconImgThree, setIconImgThree] = useState('');
    const { post, setCurrentId, i  } = props;
    const [timeLeft, setTimeLeft] = useState(null);
    

    const handleSubmit = () =>{

      props.onSubmit(post);
      
    }

  

    const delay = 500; // ms


  useEffect(() => {
    
      
     
    iconTestTwo();

   
   
  })
  

    const iconTestTwo = async() => {

      if(post.UNGoals == 'No Poverty')
      {
         setIconImg(iconOne);
      }

      
      else if(post.UNGoals == undefined || post.UNGoals == '')
      {
        
         setIconImg(NoIcon);
        
       
      }
   


      else if('Zero Hunger' == post.UNGoals )
      {
        
        setIconImg(Icon);
        
       
      }

      else if('Good Health and Well-Being' == post.UNGoals || post.UNGoals == 'Health and Well-Being')
      {
        
         setIconImg(iconThree);
       

      }


      else if('Gender Equality' == post.UNGoals )
      {
          setIconImg(iconFive);
       
        
      }
      else if('Clean Water and Sanitation' == post.UNGoals )
      {
         setIconImg(iconSix);   
      }
      
      else if('Decent Jobs and Economic Growth'== post.UNGoals )
      {
         setIconImg(iconEight);
       
  
      }
      else if('Reduced Inequality' == post.UNGoals || 'Less Inequality' == post.UNGoals || post.UNGoals=='Reduced Inequalities' )
      {
         setIconImg(iconTen);
      
      }
      else if('Responsible Consumption and Production' == post.UNGoals)
      {
         setIconImg(iconTwelve);
       
      }
      else if('Climate Action' == post.UNGoals)
      {
         setIconImg(iconThirteen);
      }
      else if('Peace Justice and Strong Institutions' == post.UNGoals || post.UNGoals == 'Peace, Justice and Strong Institutions')
      {
          setIconImg(iconSixteen);
       
      }
      else if('Partnerships for the Goals' == post.UNGoals || post.UNGoals == 'Partnership for Action' )
      {
          setIconImg(iconSeventeen);
       
   
      }

      else if('Quality of Education' == post.UNGoals )
      {
         setIconImg(iconFour);
      
        
      }

      else if('Affordable and Clean Energy' == post.UNGoals || 'Affordable and Clean Water' == post.UNGoals )
      {
         setIconImg(iconSeven);
        
       
      }


      else if('Industry Innovation and Infrastructure' == post.UNGoals || 'Industri Innovation and Infrastructure' == post.UNGoals)
      {
         setIconImg(iconNine);
        
       
      }


      else if('Sustainable Cities and Communities' == post.UNGoals)
      {
         setIconImg(iconElevenRight);
        
    
      }
      else if('Life Below Water' == post.UNGoals )
      {
         setIconImg(iconFourteen);
        
       
      }

      else if('Life On Land' == post.UNGoals)
      {
         setIconImg(iconFifteen);
       
        
      }
      else{
        setIconImg(NoIcon);

      }

    }

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  
    const location = useLocation();
  
    useEffect(() => {
      const token = user?.token;
  
      if (token) {
        const decodedToken = decode(token);
  
        if (decodedToken.exp * 1000 < new Date().getTime());
      }
  
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);
    
    
  return (
    
    <>
   
    
   <div className='LinkCardBox' onClick={handleSubmit}>

    
   <img loading="lazy" src={iconImg} className='LinkCardIcon' />
   
 
   
   <div  className='LinkCardopen'>Read more...</div>

  
    

    <div className='LinkCardProjectNumber'>{post.ProjectNUmber}</div>
    <div className='LinkCardCountry'>{post.Country}</div>
    
    <div className='LinkCardTitle'>{post.title}</div>
    
    <Suspense fallback={<div>Loading...</div>}>
    <img  loading="lazy" src={'https://storage.googleapis.com/new_card/'  + post.creator + '.PNG'} className='LinkCardImg' />
    </Suspense>
 

    </div>

    
  </>
  )
}

export default Post


/*
  <div  className='LinkCardopen'>Read more...</div>

<iframe loading="lazy"  src={'https://storage.googleapis.com/ewbdk32/undefined/' + post.selectedFile} className='LinkCardImg' frameBorder="0"/>
 



<iframe loading="lazy"  src={'https://drive.google.com/uc?id=' + post.selectedFile} className='LinkCardImg' frameBorder="0"/>
 
'https://storage.googleapis.com/test_bucket_dsak/6ProjectTest.pdf'
 <iframe loading="lazy"  src={post.pdfFile} className='LinkCardImg' frameBorder="0"/>
    
    
    <Card className={classes.card} onClick={handleSubmit}>
    <CardMedia className={classes.media} image={post.selectedFile} title={post.title} />
    <div className={classes.overlay}>
      <Typography variant="h6">{post.creator}</Typography>
      <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
    </div>
    <div className={classes.overlay2}>
      <Button style={{ color: 'white' }} onClick={()=>setCurrentId(post._id)} size="small" ><MoreHorizIcon fontSize="default" /></Button>
    </div>
    <div className={classes.details}>
      <Typography variant="body2" color="textSecondary" component="h2">{post.tags.map((tag) => `#${tag} `)}</Typography>
    </div>
    <Typography className={classes.title} gutterBottom variant="h5" component="h2">{post.title}</Typography>
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">{post.message}</Typography>
    </CardContent>
    
    <CardActions className={classes.cardActions}>
      <Button size="small" color="primary" onClick={() => dispatch(likePost(post._id))}><ThumbUpAltIcon fontSize="small" /> Like {post.likeCount} </Button>
      <Button size="small" color="primary" onClick={() => dispatch(deletePost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button>
    </CardActions>
  </Card>


<img src="https://drive.google.com/uc?export=view&id=19H4KbAf7m5xanchuZ6SqpZgz5Ricx5tA" alt="drive image"/>


  

 <div className='LinkCardTitle'>{post.title}</div>
    {iconImgTwo && <>
    <img src={iconImgTwo} className='LinkCardIconAddUN'/>
    </>}
    {iconImgThree && <>
    <img src={iconImg} className='LinkCardIconAddUNTwo'/>
    </>}
   
     {iconImg ? <>
    <img src={iconImg} className='LinkCardIcon'/>
    </>:
    <>
    <div className='greenBox'></div>
    </>}

*/