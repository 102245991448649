import React, {useState, useEffect} from 'react'
import {FormControl, InputLabel, Select,MenuItem } from '@mui/material';
import './SearchMenu.css';

function SearchMenu(props) {
  const [activities, setActivities] = useState('');
  const [unWorldGoals, setUnWorldGoals] = useState('');
  const [country, setCountry] = useState('');
  const [isShownFileExplorer, setIsShownFileExplorer] = useState(false);
  const handleChangeActivities = (event) => {

    setActivities(event.target.value);
  }
  const {zeroSet} = props;
  useEffect(() => {
    console.log("zer set child: ", zeroSet);
    setCountry(zeroSet)
  
  }, [])
  

  

  const handleChangeUnWorldGoals = (event) => {
    setCountry('');
    setUnWorldGoals(event.target.value);
    props.onSubmit(event.target.value);
  }

  const handleChangeCountry = (event) => {
    setUnWorldGoals('');
    setCountry(event.target.value);
    props.onSubmit(event.target.value);
    console.log("from sideMenu: ",event.target.value)
    
  }

  const sendData = (event) =>{

    
   

  }


  return (
    <div >
        <div className='FindProjectTitle'>Find Project</div>
        
        <div className='FindProjectCountryBox'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="Country"
              onChange={handleChangeCountry}
              
            >
              
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
              <MenuItem value={"Bhutan"}>Bhutan</MenuItem>
              <MenuItem value={"Bolivia"}>Bolivia</MenuItem>
              <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
              <MenuItem value={"Congo"}>Congo</MenuItem>
              <MenuItem value={"Denmark"}>Denmark</MenuItem>
              <MenuItem value={"Ethiopia"}>Ethiopia</MenuItem>
              <MenuItem value={"Gambia"}>Gambia</MenuItem>
              <MenuItem value={"Ghana"}>Ghana</MenuItem>
              <MenuItem value={"Haiti"}>Haiti</MenuItem>
              <MenuItem value={"India"}>India</MenuItem>
              <MenuItem value={"Ivory Coast"}>Ivory Coast</MenuItem>
              <MenuItem value={"Kenya"}>Kenya</MenuItem>
              <MenuItem value={"Liberia"}>Liberia</MenuItem>
              <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
              <MenuItem value={"Palestine"}>Palestine</MenuItem>
              <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
              <MenuItem value={"Sierra Leone"}>Sierra Leone</MenuItem>
              <MenuItem value={"Somali Land"}>Somali Land</MenuItem>
              <MenuItem value={"South Sudan"}>South Sudan</MenuItem>
              <MenuItem value={"Togo"}>Togo</MenuItem>
              <MenuItem value={"Tanzania"}>Tanzania</MenuItem>
              <MenuItem value={"Zimbabwe"}>Zimbabwe</MenuItem>
            </Select>
          </FormControl>
          
      
          
        </div>
        
    
        
       

      


    </div>
  )
}

export default SearchMenu


/*

<div className='Or'>
            or
          </div>

        


        
        <div className='FindProjectActivitiesBox'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">EWB-Dk Focus Goals</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={unWorldGoals}
              label="UnWorldGoals"
              onChange={handleChangeUnWorldGoals}
              
            >
              <MenuItem value='No Poverty'>No Poverty</MenuItem>
              <MenuItem value='Zero Hunger'>Zero Hunger</MenuItem>
              <MenuItem value='Good Health and Well-Being'>Good Health and Well-Being</MenuItem>
              <MenuItem value='Gender Equality'>Gender Equality</MenuItem>
              <MenuItem value='Clean Water and Sanitation'>Clean Water and Sanitation</MenuItem>
              <MenuItem value='Sustainable Energy'>Sustainable Energy</MenuItem>
              <MenuItem value='Decent Jobs and Economic Growth'>Decent Jobs and Economic Growth</MenuItem>
              <MenuItem value='Less Inequality'>Less Inequality</MenuItem>
              <MenuItem value='Responsible Consumption and Production'>Responsible Consumption and Production</MenuItem>
              <MenuItem value='Climate Action'>Climate Action</MenuItem>
              <MenuItem value='Peace Justice and Strong Institutions'>Peace, Justice and Strong Institutions</MenuItem>
              <MenuItem value='Partnerships for the Goals'>Partnership for the Goals</MenuItem>
            </Select>
          </FormControl>
        </div>



        <div className='FindProjectSearchBTN'></div>
        <div className='FindProjectSearchBTNText'>Search</div>

          {isShownFileExplorer && (
            <div className='focusUNWorldGoal'>
              EWB-Dk Focus World Goals
            </div>
          )}

 onMouseEnter={() => setIsShownFileExplorer(true)} onMouseLeave={() => setIsShownFileExplorer(false)}

        
        
        <div className='FindProjectActivitiesBox'>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Activities</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={activities}
              label="Activities"
              onChange={handleChangeActivities}
              
            >
              <MenuItem value={10}>Water and Sanitation</MenuItem>
              <MenuItem value={20}>Environment</MenuItem>
              <MenuItem value={30}>Food Security</MenuItem>
              <MenuItem value={40}>Disasterrelief</MenuItem>
              <MenuItem value={50}>Construction</MenuItem>
              <MenuItem value={60}>Capacity Building</MenuItem>
            </Select>
          </FormControl>
        </div>

        
   const clearCountry = () => {
    setCountry('');
  }
  const clearUNGoals = () => {
    setUnWorldGoals('');
  }

*/