import React from 'react';

import './CheckBox.css';
import checkBox from '../../img/Vector.png';


function CheckBox() {
  return (
    <div></div>
        


  )
}

export default CheckBox

