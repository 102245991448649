import { DELETE, AUTH, FETCH_ALL_USERS } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

  
    dispatch({ type: AUTH, data });

    router('/posts');
  } catch (error) {
    console.log(error);
  }
};


export const signup = (formData, router) => async (dispatch) => {
    try {
      
      const cookie = localStorage.getItem("profile");
      console.log(JSON.parse(localStorage.getItem('profile')).token);
   
      const { data } = await api.signUp(formData);
  
      

      console.log("data", data)

      dispatch({ type: AUTH, data });
  
      router('/');
    } catch (error) {
      console.log(error);
    }
  };

  export const getUsers = () => async (dispatch) => {
    try {
     
      const { data } = await api.fetchUsers();
      console.log("from auth.js: ", data);
      dispatch({ type: FETCH_ALL_USERS, payload: data });
      return data
     
    } catch (error) {
      console.log(error);
    }
  };



  
export const deleteUser = (id) => async (dispatch) => {
  try {
    console.log("got user for id: ", id);
    await api.deleteUser(id);

    console.log("got user for delete: ");
    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};