
import Navbar from '../Navbar/Navbar';
import SideMenu from '../SideMenu/SideMenu';
//import SearchMenu from '../SearchMenu/SearchMenu';
import Footer from '../Footer/Footer';
import CheckBox from '../CheckBox/CheckBox';
import { signin, signup } from '../../actions/auth';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Group from './img/Btms.png';
import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
//import Auth from '../Auth/Auth.js';
import { getPostsBySearch } from '../../actions/posts';
import decode from 'jwt-decode';
import * as actionType from '../../constants/actionTypes';
import Map from '../Map/Map';

import previousBtn from '../UploadProject/img/UploadPageButtonNew.png'; 
import SearchMenu from '../SearchMenu/SearchMenu';
import mapSide from './img/mapSide.png';
import projectMapHeader from './img/projectMapHeader.png';
import iconOne from '../../LogoBox/1Afskaf_fattigdom.PNG';
import iconTwo from '../../LogoBox/2Stop_sult.PNG';
import iconThree from '../../LogoBox/3Sundhed_og_trivsel.PNG';
import iconFour from '../../LogoBox/4_E-WEB-Goal-04.png';
import iconFive from '../../LogoBox/5Ligestilling_mellem_køn.PNG';
import iconSix from '../../LogoBox/6Rent_vand_sanitet.PNG';
import iconSeven from '../../LogoBox/7Baeredygtig_energi.PNG';
import iconEight from '../../LogoBox/8Anstaendige_jobs_.PNG';
import iconNine from '../../LogoBox/9_E-WEB-Goal-09.png';
import iconTen from '../../LogoBox/10Mindre_ulighed.PNG';
import iconEleven from '../../LogoBox/11SustainableCitiesAndCo.jpg';
import iconTwelve from '../../LogoBox/12Ansvarligt_forbrug.PNG';
import iconThirteen from '../../LogoBox/13_E-WEB-Goal-15.PNG';
import iconFourteen from '../../LogoBox/14_E-WEB-Goal-14.png';
import iconFifteen from '../../LogoBox/15_E-WEB-Goal-15.png';
import iconSixteen from '../../LogoBox/16Fred.PNG';
import iconSeventeen from '../../LogoBox/17Partnerskab_for_handling.PNG';
import searchBTN from '../Search/img/btn.png';
import allProjectBtn from '../Navbar/img/allProjectBtn.png';

import { useNavigate, useLocation } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';

import linkCardImg from './img/LinkCardImg.png';
import '../SideMenu/SideMenu.css';

import Ok from './img/Ok.png';
import cookies from './img/cookies.png';
import './FoodSecurity.css';
import Search from '../Search/Search';
import { Container, AppBar, Typography, Grow, Grid,TextField, Button, Paper } from '@material-ui/core';
//import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import { getPosts } from '../../actions/posts';
import UploadProject from '../UploadProject/UploadProject';
import { display } from '@mui/system';
import img from '../Home/img/EWB-DKMapSide.png';
import createAdminBtn from './img/createAdminBtn.png';

import posts from '../../reducers/posts';

import auth from '../../reducers/auth';
import CookieConsent from "react-cookie-consent";

import {FormControl, InputLabel, Select,MenuItem } from '@mui/material';
import '../SearchMenu/SearchMenu.css';

import FacebookIcon1 from '../../img/Social/FacebookIcon.PNG'
import LinkedInicon1 from '../../img/Social/LinkedInicon.PNG'
import YouTubeIcon1 from '../../img/Social/YouTubeIcon.PNG'
import InstaLogo from '../../img/Social/InstaLogo.png'
import ToTop from './img/ToTop.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };
function FoodSecurity({valueFromLink}) {
  
  //const Footer = React.lazy(() => import('../Footer/Footer'));
  const Auth = React.lazy(() => import('../Auth/Auth.js'));
  //const Map = React.lazy(() => import('../Map/Map'));
  //const UploadProject = React.lazy(() => import('../UploadProject/UploadProject'));
  const Posts = React.lazy(() => import('../Posts/Posts'));
  //const SearchMenu = React.lazy(() => import('../SearchMenu/SearchMenu'));
  //const Search = React.lazy(() => import('../Search/Search'));

  const [form, setForm] = useState(initialState);
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(true);
  const classes = useStyles();
  const [isShownHover, setIsShownHover] = useState(false); 
  const [isShownTitleOnMap, setIsShownTitleOnMap] = useState(false);
  const {posts} = useSelector((state) => state.posts);
  const query = useQuery();
  const searchQuery = query.get('searchQuery');
  const [search, setSearch] = useState('');
  const [waterToggle, setWaterToggle] = useState(true);
  const [buildingToggle, setBuildingToggle] = useState(true);
  const [energyToggle, setEnergyToggle] = useState(true);
  const [ictToggle, setIctToggle] = useState(true);
  const [wasteToggle, setWasterToggle] = useState(true);
  const [riskToggle, setRiskToggle] = useState(true);
  const [toggleCookies, setToggleCookies] = useState(false);
  const [value, setValue] = useState(true);
  const [toggleMapSite, setToggleMapSite] = useState(false);
  const [toggleProcejtBtnHover, setToggleProcejtBtnHover] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const {users} = useSelector((state) => state.auth);
  const [adminToggle, setAdminToggle] = useState(false);
  const [NavbarAdminBtn, setNavbarAdminBtn] = useState();
  const [zeroSet, setzeroSet] = useState('');
  const [numberOfProjects, setNumberOfProjects] = useState();
  const [postObj, setPostObj] = useState({_id:0, title:'', message:'', selectedFile:'', creator:'', tags:[], selectedFileIcon:'',UNGoals:'',
  Country:'',
  ProjectNUmber:'',
  SeventeenUNGoals:'',
  CoordinatesLat:'',
  CoordinatesLong:'',
  pdfFile:'' });
  
  const [tags, setTags] = useState([]);
  const history = useNavigate();

  const [allPosts, setAllPosts] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  
  const location = useLocation();

  const [imageFile, setimageFile] = useState();
  const [country, setCountry] = useState('');


  const handleChangeCountry = (event) => {
    
    setCountry(event.target.value);
   
    console.log("from sideMenu: ",event.target.value)

    handleAddChip(event.target.value);
   
    setSearch('');
   
    setValue(true);
    setToggleMapSite(true);
 
    setIsToggled(true);
    setValue(true);


    setWaterToggle(true);
    setBuildingToggle(true);
    setEnergyToggle(true);
    setIctToggle(true);
    setWasterToggle(true);
    setRiskToggle(true);
  
    
  }

  useEffect(() => {
    //fetchData();

    //let newfile = new File(['https://0rns745gll.execute-api.us-west-2.amazonaws.com/v1/s3?key=testiugserver/food.PNG'], 'newfile');
    //setimageFile(newfile);

    
    
    
  }, [])
 
  

  async function fetchData() {
    // You can await here
    console.log("getting data");
    await dispatch(getPosts());
    
    //const lisuser  = await dispatch(getUsers());
    
    //console.log("user: ", lisuser)
    console.log("have data", posts);
    //await setTimeLeft(5);
    // ...
  }
  async function fetchDataBySearh(search,tags) {
    // You can await here
    //await dispatch(getPostsBySearch({ search, tags: tags.join(',') }));

    
    let searchnext = [];
    //console.log("from search", posts);
    console.log("posts.length>0: ", posts.length>0);
    if(posts.length>0)
    {
      searchnext = [];

      searchnext = allPosts.map(post => post.tags.map(tagNew => tagNew=='Building and Construction'));
      //console.log(allPosts.map(post => console.log(post.tags.map(tagNew => console.log(tagNew=='Building and Construction')))));
      console.log(searchnext[1]);
      //allPosts.tags.map(tag => {if(tag=='Water and Sanitation'){ console.log("tag=='Water and S works!")}})
     // console.log("posts.tags", posts.UNGoals);
      //searchnext = allPosts.tags.map(postSearch => postSearch==tags);
      await setAllPosts(searchnext);
    }
    console.log("from search new: ", allPosts);
    
    
 
    //const lisuser  = await dispatch(getUsers());
    
    //console.log("user: ", lisuser)
   // console.log("have data");
    //await setTimeLeft(5);
    // ...
  }

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime());
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);
 




 


  useEffect(() => {

   
    
    console.log("from search two", tags)
    if (search.trim() || tags && tags.length != 0 || tags != null && search !='' || search != null) {
      
      //fetchDataBySearh();

      console.log("from search two if not", tags)
     
        dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
        try{
          const idFromUrl = window.location.href.split("/")[6];
          const newTag = window.location.href.split("/")[5].split("=")[2].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");
          const newSearch =  window.location.href.split("/")[5].split("=")[1].split("&")[0].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");
          if(newTag && search=='none')
          {
            history(`/posts/search?searchQuery=${newSearch || 'none'}&tags=${newTag}/${idFromUrl || ''}`);
          
          }else if(newSearch && search=='none'){
            history(`/posts/search?searchQuery=${newSearch}&tags=${newTag || 'none'}/${idFromUrl || ''}`);
          
          } else if(newTag.length != 0 && !idFromUrl)
          {
            history(`/posts/search?searchQuery=${search || 'none'}&tags=${newTag || 'none'}/all`);
          }else{
            history(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',') || 'none'}/${idFromUrl || ''}`);
    
          }
          
          if(tags.length != 0 && !idFromUrl)
          {
            history(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',') || 'none'}/`);
          }
        
       
        
  
        
        
        }
        catch{
          history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`);
          
        }
        //console.log("xxxxxxxxxxPPPxxxxxx", newTag);
       
      
        if(tags.length == 0 && search.length ==0)
        {
          //history(`/posts/search?searchQuery=${'none'}&tags=${'none'}`);
     

        }

     
       
     
      if(tags.length == 0 && search=='' ){
        console.log("from if: ", tags);
        dispatch(getPosts());
        
       
        


       
      }
    }
   
  }, [search, tags])
  

      


useEffect(() => {
  
  const idFromUrl = window.location.href.split("/")[6];
  const newTag = window.location.href.split("/")[5].split("=")[2].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");
  const newSearch =  window.location.href.split("/")[5].split("=")[1].split("&")[0].replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ").replace("%20", " ");

//console.log("xxxxxxxxxxxxxxxx", newSearch);


if(newSearch != 'none'){
  setSearch(newSearch);
  setValue(true); 

  setToggleMapSite(true);
} 
if(newTag != 'none'){
  handleAddChip(newTag);
  setValue(true); 
  
  setToggleMapSite(true);
}

if(window.location.href == 'http://localhost:3000/#/posts/search?searchQuery=none&tags=none'){
  
  setValue(true); 
  
  setToggleMapSite(true);
}

if(!idFromUrl){
  //setValue(true); 
  
  //setToggleMapSite(true);
  

}

if(idFromUrl=='all' && newTag == 'none')
{
  //handleAddChip(newTag);
  setValue(true); 
  
  setToggleMapSite(true);

}

}, [window.location.href])

  

useEffect(() => {
  window.scrollTo(0, 0)
}, [])
 const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.location.reload();
  };
  
  window.addEventListener('scroll', toggleVisible);
  


  const handleAddChip = (tag) => setTags([tag]);

  const handleDeleteChip = (chipToDelete) => setTags(tags.filter((tag) => tag !== chipToDelete));





  const handleToggle = () => {
    setIsToggled(!isToggled);
    setToggleMapSite(true);
  }

  const handleWater = (event) =>{
 
    console.log(event.currentTarget.id);
    handleAddChip(event.currentTarget.id);
    setSearch('');
    setIsToggled(true);
    setValue(true);
    setToggleMapSite(true);
    

    
    handleWaterToggle();
  
  }

  const handleWaterDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setWaterToggle(true);

  }

  const handleBuildingDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setBuildingToggle(true);
    

  }

  const handleEnergyDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setEnergyToggle(true);

  }


  const handleICTDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setIctToggle(true);

  }


  const handleWasteDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setWasterToggle(true);

  }

  const handleRiskDelete = (event) =>{
    handleDeleteChip(event.currentTarget.id);
    setRiskToggle(true);

  }

  const handleWaterToggle = () => {
    setWaterToggle(!waterToggle);
    


    setBuildingToggle(true);
    setWasterToggle(true);
    setEnergyToggle(true);
    setIctToggle(true);
    setRiskToggle(true);
    setCountry('');

    
    
  }

  const handleBuildingToggle = (event) => {
    setBuildingToggle(!buildingToggle);
    handleAddChip(event.currentTarget.id);
    setIsToggled(true);
    setSearch('');
    setEnergyToggle(true);
    setWasterToggle(true);
    setWaterToggle(true);
    setIctToggle(true);
    setRiskToggle(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleEnergyToggle = (event) => {
    setEnergyToggle(!energyToggle);
    handleAddChip(event.currentTarget.id);
    setIsToggled(true);
    setSearch('');

    setBuildingToggle(true);
    setWasterToggle(true);
    setWaterToggle(true);
    setIctToggle(true);
    setRiskToggle(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
    
  }

  const handleICTToggle = (event) => {
    setIctToggle(!ictToggle);
    handleAddChip(event.currentTarget.id);
    setIsToggled(true);
    setSearch('');

    setBuildingToggle(true);
    setWasterToggle(true);
    setWaterToggle(true);
    setEnergyToggle(true);
    setRiskToggle(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleWasteToggle = (event) => {
    setWasterToggle(!wasteToggle);
    if(event.currentTarget.id == 'Risk Reduction'){
      handleAddChip('Risk Reduction and Disaster Relief');
      
    }
    else{
      handleAddChip(event.currentTarget.id);
    }
    
    setIsToggled(true);
    setSearch('');

    setBuildingToggle(true);
    setEnergyToggle(true);
    setWaterToggle(true);
    setIctToggle(true);
    setRiskToggle(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleRiskToggle = (event) => {
    setRiskToggle(!riskToggle);
    handleAddChip(event.currentTarget.id);
    setSearch('');

    setIsToggled(true);
    setBuildingToggle(true);
    setWasterToggle(true);
    setWaterToggle(true);
    setIctToggle(true);
    setEnergyToggle(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const getData = (data) =>{

    handleAddChip(data);
    console.log("from me", data);
    setSearch('');
    setzeroSet(data);
    setValue(true);
    setToggleMapSite(true);
    setToggleMapSite(true);
    setIsToggled(true);
    setValue(true);
    setToggleMapSite(true);
    
    if(data == "")
    {
     setTags([]);
    }
    
 
  }

  const getSearchData = (data) => {
    console.log("from me", data);
    setSearch(data);
    setToggleMapSite(true);
    setIsToggled(true);
    setValue(true);
    
    setTags([]);
  
    setCountry('');

    setWaterToggle(true);
    setBuildingToggle(true);
    setEnergyToggle(true);
    setIctToggle(true);
    setWasterToggle(true);
    setRiskToggle(true);
  }

 


  const handleOneClick = () =>{
    handleAddChip('No Poverty');
    setSearch('');
    setIsToggled(true);
    setToggleMapSite(true);
    setValue(true);
    scrollToTop();
    setCountry('');
  }


  const handleTwoClick = () =>{
    handleAddChip('Zero Hunger');
    setSearch('');
    setIsToggled(true);
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleThreeClick = () =>{
    handleAddChip('Good Health and Well-Being');
    setToggleMapSite(true);
    setSearch('');
    setValue(true);
    setCountry('');
  }


  const handleFourClick = () =>{
    handleAddChip('Quality Education');
    setToggleMapSite(true);
    setSearch('');
    setValue(true);
    setCountry('');
  }

  const handleFiveClick = () =>{
    handleAddChip('Gender Equality');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleSixClick = () =>{
    handleAddChip('Clean Water and Sanitation');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleSevenClick = () =>{
    handleAddChip('Affordable and Clean Energy');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleEightClick = () =>{
    handleAddChip('Decent Work and Economic Growth');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleNineClick = () =>{
    handleAddChip('Industry, Innovation and Infrastructure');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleTenClick = () =>{
    handleAddChip('Reduced Inequalities');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleElevenClick = () =>{
    handleAddChip('Sustainable Cities and Communities');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleTwelveClick = () =>{
    handleAddChip('Responsible Consumption and Production');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleThirteenClick = () =>{
    handleAddChip('Climate Action');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleFourteenClick = () =>{
    handleAddChip('Life Below Water');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleFifteenClick = () =>{
    handleAddChip('Life On Land');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleSixteenClick = () =>{
    handleAddChip('Peace Justice and Strong Institutions');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }
  const handleSeventeenClick = () =>{
    handleAddChip('Partnerships for the Goals');
    setSearch('');
    setToggleMapSite(true);
    setValue(true);
    setCountry('');
  }

  const handleMapLink = () =>{
    setWaterToggle(true);
    setBuildingToggle(true);
    setEnergyToggle(true);
    setIctToggle(true);
    setWasterToggle(true);
    setRiskToggle(true);

    setToggleMapSite(false);
    setIsToggled(true);
    history(`/posts/search?searchQuery=${'none'}&tags=${'none'}/`);
    setCountry('');
  }

  const handleAllProjectBtn = () =>{

    setWaterToggle(true);
    setBuildingToggle(true);
    setEnergyToggle(true);
    setIctToggle(true);
    setWasterToggle(true);
    setRiskToggle(true);


    setToggleMapSite(true);
    setIsToggled(true);
    setPostObj({_id:0, title:'', message:'', selectedFile:'', creator:'', tags:[], selectedFileIcon:'',UNGoals:'',
    Country:'',
    ProjectNUmber:'',
    SeventeenUNGoals:'',
    CoordinatesLat:'',
    CoordinatesLong:'',
    pdfFile:'' });
    //history('/posts');
    setSearch('');
    setTags([]);
    setValue(true);
    setzeroSet('');
    console.log("zero set parent", zeroSet);
    history(`/posts/search?searchQuery=${'none'}&tags=${'none'}`);
    setCountry('');
    
    

  }

  const toggleValueForPost = () =>{
    setValue(!value);
  }

  const getDataTwo = (data) =>{
      
    console.log("test form food",data);
    setIsToggled(false);
    setPostObj(data);
    setNumberOfProjects(data);
     //setData(data);
    // setToggle(!toggle);
     
     
     
   }

  
   const topFunction = () => {
    window.scrollTo(0, 0)
  }
   

  const handleAdmin = () => {

    setAdminToggle(true);
    setToggleMapSite(false);
    setIsToggled(true);
    
  }
  
  const getProjects = (data) => {
    console.log("data ", data?.length);
  }

  const handleCookies = () => {
    window.scrollTo(0, 0);
  }
  
  const getallDataBtn = (data) => {
    //handleAllProjectBtn();
    console.log("from navbar", data)
    setNavbarAdminBtn(data);
    console.log("from navbar NavbarAdminBtn", NavbarAdminBtn)
  }
  return (

    
    <div>

      


      <div className='fadeInCookie'>
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="myAwesomeCookieName2"
          
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website only uses necessary cookies{" "}

         
        </CookieConsent>
        </div>


     
        <div className='navbar'><Navbar onSubmit={getallDataBtn}></Navbar></div>

        
          <div onClick={handleAllProjectBtn}>
            <div className='boxAllProjectstwo'>projects</div>
          </div>
        


        {user?.result &&
          <>
          <div onClick={handleAdmin} >
            <img src={createAdminBtn} className='createAdminBtn'></img>
          </div>

          {adminToggle &&
          <>
          <div className='fullBox'>
          <Suspense fallback={<div></div>}>
            <Auth/>
          </Suspense>
          </div>
          
          </>}
          </>
        }

       



         <div>
         {user?.result &&
        <div onClick={handleToggle}>
          <img src={previousBtn} className='UploadProjectButton'></img>
        </div>
        
         }

         
        </div>

      
        
        
        <div className='row'>
        {!toggleMapSite &&
        <>
       
          
       {!user?.result ? (
        <div  className='MapSideLinkGroupFood' onClick={handleMapLink}>Map</div>
       ):<div  className='MapSideLinkGroupFoodAdmin' onClick={handleMapLink}>Map</div>}

        <div onMouseEnter={() =>setIsShownTitleOnMap(true)} onMouseLeave={() => setIsShownTitleOnMap(false)}>
          <div className='TitleOnMap'></div>
        </div>
          {isShownTitleOnMap && (
            <div className='isShownTitleOnMap'>
              Hover a marker and the EWB-Dk project number will appear. Use the number (or parts of it) in the search field to find the project
            </div>
          )}
        
        
        <div className='mapSitePo'>
          
        
          <Map dataForMap={NavbarAdminBtn} setCurrentId={setCurrentId} onSubmitTwo={getDataTwo}/>
       
        </div>
          
          <div className='displayAll'>
            <img src={iconOne} className='iconOne' onClick={handleOneClick}/>
            <img src={iconTwo} className='iconTwo'  onClick={handleTwoClick}/>
            <img src={iconThree} className='iconThree'  onClick={handleThreeClick}/>
            <img src={iconFour} className='iconFour' onClick={handleFourClick}/>
            <img src={iconFive} className='iconFive' onClick={handleFiveClick}/>
            <img src={iconSix} className='iconSix'  onClick={handleSixClick}/>
            <img src={iconSeven} className='iconSeven' onClick={handleSevenClick}/>
            <img src={iconEight} className='iconEight' onClick={handleEightClick}/>
            <img src={iconNine} className='iconNine'onClick={handleNineClick}/>
            <img src={iconTen} className='iconTen'onClick={handleTenClick}/>
            <img src={iconEleven} className='iconEleven' onClick={handleElevenClick}/>
            <img src={iconTwelve} className='iconTwelve' onClick={handleTwelveClick}/>
            <img src={iconThirteen} className='iconThirteen'  onClick={handleThirteenClick}/>
            <img src={iconFourteen} className='iconFourteen' onClick={handleFourteenClick}/>
            <img src={iconFifteen} className='iconFifteen'onClick={handleFifteenClick}/>
            <img src={iconSixteen} className='iconSixteen' onClick={handleSixteenClick}/>
            <img src={iconSeventeen} className='iconSeventeen' onClick={handleSeventeenClick}/>
            
           
              
            
            </div>
          

     
      
      
       <Footer/>
          
       
        </>
        }
       
      
       
           
         
           
          

          {toggleMapSite &&
          <div> 
              
              {!user?.result ? (
                <div  className='MapSideLinkGroupFood' src={mapSide} onClick={handleMapLink}>map</div>
              ):<div  className='MapSideLinkGroupFoodAdmin' src={mapSide} onClick={handleMapLink}>map</div>}
            
              {isToggled &&
              <>
                <div className='linkcardPageHeader'>Read about Engineers Without Borders - Denmark's current and completed projects
                </div>
                <div className='postName'>
                 
                 
                <Suspense fallback={<div></div>}>
                  <Grid item xs={11} sm={12} >
                    <div onClick={toggleValueForPost}>
                      <Posts  dataNew={value} setCurrentId={setCurrentId} onSubmit={getDataTwo} />
                    </div>
                    </Grid>
                 
                </Suspense>
               
                </div>
              
      
        
      

              </>
                    
              }
          </div>
                  
        }
        
        
        <div>
        {!isToggled &&
        <>
      
          <div className='Uploadform'>
                      
                      <Suspense fallback={<div></div>}>
                        <Grid item xs={11} sm={12}>
                        <UploadProject postObj={postObj} currentId={currentId} setCurrentId={setCurrentId}/>
                        </Grid>   
                        
                        <span className='FooterBoxUP'>
                            <p className='FooterBoxTextOneUP'> Ingeniører Uden Grænser/ Engineers Without Borders Denmark</p>
                          <p className='FooterBoxTextTwoUP'>Office: +4570274006/ Email: info@iug.dk</p>

                          </span>
                      </Suspense> 
                      
                                   
                      
          </div>
          </>
          }
        </div>
        
        
        <Search onSubmit={getSearchData}/>
        

       
        
       
         <div className='projectTypeBox'>
         
         {waterToggle ? 
            <div className='projectTypeBoxOne' id="Water and Sanitation" onClick={handleWater} >

              <div className='projectTypeTextone'>Water and Sanitation</div>
      
            </div>
            : 
            <div className='projectTypeBoxOneToggle' id="Water and Sanitation"  >

            <div className='projectTypeTextone'>Water and Sanitation</div>
    
          </div>


          }

          {buildingToggle ? 
            <div className='projectTypeBoxTwo' id="Building and Construction" onClick={handleBuildingToggle}>
            <div className='projectTypeTextTwo'><span>Building and Construction</span></div>
            </div>
            :
         
            <div className='projectTypeBoxTwoToggle' id="Building and Construction"  >
              <div className='projectTypeTextTwo'><span>Building and Construction</span></div>
            </div>
          }

            {energyToggle ? 
            
              <div className='projectTypeBoxThree' id="Sustainable Energy" onClick={handleEnergyToggle}>
              <div className='projectTypeTextThree'>Sustainable Energy</div>
              </div>
            
            :
            <div className='projectTypeBoxThreeToggle' id="Sustainable Energy"  >
            <div className='projectTypeTextThree'><span>Sustainable Energy</span></div>
          </div>
            }

          {ictToggle ? 
            <div className='projectTypeBoxFour' id="Information and Technology" onClick={handleICTToggle}>
            <div className='projectTypeTextFour'>Information and Technology</div>
            </div>
            :
            <div className='projectTypeBoxFourToggle' id="Information and Technology"  >
            <div className='projectTypeTextFour'><span>Information and Technology</span></div>
          </div>
          }

          {wasteToggle ? 
            <div className='projectTypeBoxFive' id="Waste Management" onClick={handleWasteToggle}>
            <div className='projectTypeTextFive'>Waste Management</div>
            </div>
            :
            <div className='projectTypeBoxFiveToggle' id="Waste Management"  >
            <div className='projectTypeTextFive'><span>Waste Management</span></div>
          </div>
            
            
            }


             {riskToggle ? 
            <div className='projectTypeBoxSix' id="Risk Reduction and Disaster Relief" onClick={handleRiskToggle}>
            <div className='projectTypeTextSix'>Risk Reduction & Disaster Relief</div>
            </div>
            :
            <div className='projectTypeBoxSixToggle' id="Risk Reduction and Disaster Relief"  >
            <div className='projectTypeTextSix'><span>Risk Reduction & Disaster Relief</span></div>
          </div>
             }


            </div>
    
       

    <div>
        <div className='FindProjectTitleTwo'>Find Project</div>
        
        <div className='FindProjectCountryBox'>
        <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Country</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={country}
              label="Country"
              onChange={handleChangeCountry}
              
            >
              
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
              <MenuItem value={"Benin"}>Benin</MenuItem>
              <MenuItem value={"Bhutan"}>Bhutan</MenuItem>
              <MenuItem value={"Bolivia"}>Bolivia</MenuItem>
              <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
              <MenuItem value={"Cameroon"}>Cameroon</MenuItem>
              <MenuItem value={"Congo"}>Congo</MenuItem>
              <MenuItem value={"Denmark"}>Denmark</MenuItem>
              <MenuItem value={"Ethiopia"}>Ethiopia</MenuItem>
              <MenuItem value={"Gambia"}>Gambia</MenuItem>
              <MenuItem value={"Ghana"}>Ghana</MenuItem>
              <MenuItem value={"Haiti"}>Haiti</MenuItem>
              <MenuItem value={"India"}>India</MenuItem>
              <MenuItem value={"Ivory Coast"}>Ivory Coast</MenuItem>
              <MenuItem value={"Kenya"}>Kenya</MenuItem>
              <MenuItem value={"Liberia"}>Liberia</MenuItem>
              <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
              <MenuItem value={"Palestine"}>Palestine</MenuItem>
              <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
              <MenuItem value={"Sierra Leone"}>Sierra Leone</MenuItem>
              <MenuItem value={"Somali Land"}>Somali Land</MenuItem>
              <MenuItem value={"South Sudan"}>South Sudan</MenuItem>
              <MenuItem value={"Togo"}>Togo</MenuItem>
              <MenuItem value={"Tanzania"}>Tanzania</MenuItem>
              <MenuItem value={"Zambia"}>Zambia</MenuItem>
              <MenuItem value={"Zimbabwe"}>Zimbabwe</MenuItem>
              <MenuItem value={"Uganda"}>Uganda</MenuItem>
            </Select>
          </FormControl>
          
      
          
        </div>
        
    
        
      
      


    </div>
          
        
        </div>
        
    </div>
   
  )
}

export default FoodSecurity


/*

    map
     <div className='SocialNetworkIconsTitleMap'>
       EWB-Dk Projects SoMe
       </div>
       <a href='https://www.facebook.com/iugdk' rel="noopener noreferrer" target="_blank">
        <img src={FacebookIcon1} className='FacebookIconsMap' />
       </a>
       <a href='https://www.linkedin.com/company/engineers-without-borders-denmark/' rel="noopener noreferrer" target="_blank">
        <img src={LinkedInicon1} className='LinkedInIconsMap' />
       </a>
       <a href='https://www.youtube.com/channel/UCzKxhsvw9yWli58wM2Go2xg' rel="noopener noreferrer" target="_blank">
        <img src={YouTubeIcon1} className='YoutubeIconsMap'/>
       </a>
       <a href='https://www.instagram.com/engineerswithoutbordersdk/' rel="noopener noreferrer" target="_blank">
        <img src={InstaLogo} className='InstaLogoMap'/>
       </a>


      project
        <div className='SocialNetworkIconsTitle'>
       EWB-Dk Projects SoMe
       </div>
       <a href='https://www.facebook.com/iugdk' rel="noopener noreferrer" target="_blank">
        <img src={FacebookIcon1} className='FacebookIcons' />
       </a>
       <a href='https://www.linkedin.com/company/engineers-without-borders-denmark/' rel="noopener noreferrer" target="_blank">
        <img src={LinkedInicon1} className='LinkedInIcons' />
       </a>
       <a href='https://www.youtube.com/channel/UCzKxhsvw9yWli58wM2Go2xg' rel="noopener noreferrer" target="_blank">
        <img src={YouTubeIcon1} className='YoutubeIcons'/>
       </a>
       <a href='https://www.instagram.com/engineerswithoutbordersdk/?hl=da' rel="noopener noreferrer" target="_blank">
        <img src={InstaLogo} className='InstaLogo'/>
       </a>
    
       {!user?.result ? (
        <img  className='MapSideLinkGroupFood' src={mapSide} onClick={handleMapLink}/>
       ):<img  className='MapSideLinkGroupFoodAdmin' src={mapSide} onClick={handleMapLink}/>}




<bottom>
          <img src={ToTop} onClick={scrollToTop} className='toTop'/>
        </bottom>
 <div className='notWorking'>
      Sorry, but this page are currently experiencing difficulty, please make use of our old project webpage: <a href='http://projekt.iug.dk/'>projekt.iug.dk</a> 
    
    </div>

    
<SearchMenu zeroSet={zeroSet} onSubmit={getData}/>

<Suspense fallback={<div>
          <input type="text" className='SearchBoxInputField'></input>
           <img src={searchBTN} className='btn2'></img>
           </div>}>
            <Search onSubmit={getSearchData}/>
        </Suspense>



<Suspense fallback={<>
          <div className='FindProjectTitleInLoad'></div>
          <div className='FindProjectCountryBoxInLoad'>Loading...</div>
        </>}>
          <SearchMenu values = {zeroSet} onSubmit={getData}/>
        </Suspense>

{isShownHover && (
          <div className='circularProgressActivityHover'>
            If the desired activity is not found within 10 seconds, there is a problem in the search, 
            perhaps there is no such activity at the moment or an unknown problem. Click 'EWB-Dk Projects' 
            and search for projects i.e. in country. Newest projects are shown first

          </div>
        )}

{waterToggle ? 
            <div className='projectTypeBoxOne' id="Water and Sanitation" onClick={handleWater} >

              <div className='projectTypeTextone'>Water and Sanitation</div>
      
            </div>
            : 
            <div className='projectTypeBoxOne' id="Water and Sanitation" onClick={handleWaterDelete} >

            <div className='projectTypeTextone'>Water and Sanitation</div>
    
          </div>


          }

          {buildingToggle ? 
            <div className='projectTypeBoxTwo' id="Building and Construction" onClick={handleBuildingToggle}>
            <div className='projectTypeTextTwo'><span>Building and Construction</span></div>
            </div>
            :
         
            <div className='projectTypeBoxTwo' id="Building and Construction" onClick={handleBuildingDelete} >
              <div className='projectTypeTextTwo'><span>Building and Construction</span></div>
            </div>
          }

            {energyToggle ? 
            
              <div className='projectTypeBoxThree' id="Sustainable Energy" onClick={handleEnergyToggle}>
              <div className='projectTypeTextThree'>Sustainable Energy</div>
              </div>
            
            :
            <div className='projectTypeBoxThree' id="Sustainable Energy" onClick={handleEnergyDelete} >
            <div className='projectTypeTextThree'><span>Sustainable Energy</span></div>
          </div>
            }

          {ictToggle ? 
            <div className='projectTypeBoxFour' id="Information and Technology" onClick={handleICTToggle}>
            <div className='projectTypeTextFour'>Information and Technology</div>
            </div>
            :
            <div className='projectTypeBoxFour' id="Information and Technology" onClick={handleICTDelete} >
            <div className='projectTypeTextFour'><span>Information and Technology</span></div>
          </div>
          }

          {wasteToggle ? 
            <div className='projectTypeBoxFive' id="Waste Management" onClick={handleWasteToggle}>
            <div className='projectTypeTextFive'>Waste Management</div>
            </div>
            :
            <div className='projectTypeBoxFive' id="Waste Management" onClick={handleWasteDelete} >
            <div className='projectTypeTextFive'><span>Waste Management</span></div>
          </div>
            
            
            }


             {riskToggle ? 
            <div className='projectTypeBoxSix' id="Risk Reduction and Disaster Relief" onClick={handleRiskToggle}>
            <div className='projectTypeTextSix'>Risk Reduction & Disaster Relief</div>
            </div>
            :
            <div className='projectTypeBoxSix' id="Risk Reduction and Disaster Relief" onClick={handleRiskDelete} >
            <div className='projectTypeTextSix'><span>Risk Reduction & Disaster Relief</span></div>
          </div>
             }



            

        <div className='linkCard'>
          <img src={linkCardImg} className='linkCardImg'></img>
          <div className='linkCardTitle'>White Tea - Solar Drying</div>
          <div className='linkCardBreadText'>The education and capacity building mission to Kenya have three main purposes. To provide the farmers with an opportunity to ...</div>
          <div className='linkCardDate'>2015</div>
        </div>
        <div className='linkCardTwo'></div>
        <div className='linkCardThree'></div>
        <div className='linkCardFour'></div>


                        <Grid item xs={12} sm={4}>
                  <Form currentId={currentId} setCurrentId={setCurrentId}/>
                </Grid>


      
      .linkCard{
    position: absolute;
    width: 16%;
    height: 40%;
    left: 23%;
    top: 40%;

    background: white;
    box-shadow: 0px 4px 4px lightgrey;
    border-radius: 3px;
   
}


.linkCardTwo{
    position: absolute;
    width: 16%;
    height: 40%;
    left: 42%;
    top: 40%;

    background: white;
    box-shadow: 0px 4px 4px lightgrey;
    border-radius: 3px;
}


.linkCardThree{
    position: absolute;
    width: 16%;
    height: 40%;
    left: 61%;
    top: 40%;

    background: white;
    box-shadow: 0px 4px 4px lightgrey;
    border-radius: 3px;
}


.linkCardFour{
    position: absolute;
    width: 16%;
    height: 40%;
    left: 80%;
    top: 40%;

    background: white;
    box-shadow: 0px 4px 4px lightgrey;
    border-radius: 3px;
}


.linkCardImg{
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0%;
    top: 0%;
    z-index: 1;
    border-radius: 5px;
}


.linkCardTitle{
    position: absolute;
    width: 167px;
    height: 17px;
    left: 4%;
    top: 60%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;



    color: #000000;

  }
  
  .linkCardBreadText{
      position: absolute;
      width: 255px;
      height: 53px;
      left: 4%;
      top: 67%;
  
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
  
      color: #000000;
  }
  
        <div className='notWorking'>
      Sorry, but this page are currently experiencing difficulty, please make use of our old project webpage: <a href='http://projekt.iug.dk/'>projekt.iug.dk</a> 
    
    </div>
  
  
  .linkCardDate{
      
      position: absolute;
      width: 30px;
      height: 16px;
      right: 4%;
      top: 90%;
  
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
   
  
  
      color: #000000;
  }


{isShown && (
            <div className='UploadProjectButtonHoverText'>
              Shift between 'All Projects' and 'Upload Projects' page
            </div>
          )}
 






          
         <div className='projectTypeBox'>
         
        
            <div className='projectTypeBoxOne' id="Water and Sanitation" onClick={handleWater} >

              <div className='projectTypeTextone'>Water and Sanitation</div>
      
            </div>
            


          

         
            <div className='projectTypeBoxTwo' id="Building and Construction" onClick={handleBuildingToggle}>
            <div className='projectTypeTextTwo'><span>Building and Construction</span></div>
            </div>
           

          
              <div className='projectTypeBoxThree' id="Sustainable Energy" onClick={handleEnergyToggle}>
              <div className='projectTypeTextThree'>Sustainable Energy</div>
              </div>
            
           

         
            <div className='projectTypeBoxFour' id="Information and Technology" onClick={handleICTToggle}>
            <div className='projectTypeTextFour'>Information and Technology</div>
            </div>
            
            

        
            <div className='projectTypeBoxFive' id="Waste Management" onClick={handleWasteToggle}>
            <div className='projectTypeTextFive'>Waste Management</div>
            </div>
          


              
            <div className='projectTypeBoxSix' id="Risk Reduction" onClick={handleRiskToggle}>
            <div className='projectTypeTextSix'>Risk Reduction & Disaster Relief</div>
            </div>
         
             
            </div>
    
*/